import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahAngsuranBedaCabang = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Inputan Penjualan
  const [juals, setJuals] = useState([]);
  const [noJual, setNoJual] = useState("");
  const [noKwitansi, setNoKwitansi] = useState(user.kodeKwitansi);
  const [noTerakhir, setNoTerakhir] = useState(user.noTerakhir);
  const [parentId, setParentId] = useState("");
  const [jualId, setJualId] = useState("");

  // Inputan Pembeli
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [suratPenarikanKolektor, setSuratPenarikanKolektor] = useState("");

  const [kodeCOA, setKodeCOA] = useState(user.coaKasir);
  const [namaCOA, setNamaCOA] = useState(user.coaKasir);
  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);

  // Inputan Motor
  const [tipe, setTipe] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");

  // Entry Angsuran
  const [suratPenarikanId, setSuratPenarikanId] = useState(null);
  const [noAngsuran, setNoAngsuran] = useState("");
  const [dari, setDari] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoFormatted, setTglJatuhTempoFormatted] = useState("");
  const [tglJatuhTempoNext, setTglJatuhTempoNext] = useState("");
  const [passwordPotongan, setPasswordPotongan] = useState("");
  const [openpasswordPotongan, setOpenPasswordPotongan] = useState(false);
  let tempToday = new Date();

  let tempPeriodeAwal = user.periode.periodeAwal.split("-");
  let tempPeriodeAwalFormatted = new Date(
    tempPeriodeAwal[0] +
      "-" +
      tempPeriodeAwal[1].toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      tempPeriodeAwal[2].toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
  ).setHours(0, 0, 0, 0);
  let tempPeriodeAkhir = new Date(user.periode.periodeAkhir).setHours(
    23,
    59,
    59
  );

  if (tempToday <= tempPeriodeAwalFormatted || tempToday >= tempPeriodeAkhir) {
    tempToday = tempPeriodeAwalFormatted;
  }

  const [inputTglBayar, setInputTglBayar] = useState(new Date(tempToday));
  const [keterlambatan, setKeterlambatan] = useState("");
  // Input
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [denda, setDenda] = useState("");
  const [hutangDenda, setHutangDenda] = useState("");
  const [hutangDendaBefore, setHutangDendaBefore] = useState("");
  const [biayaTarik, setBiayaTarik] = useState("");
  const [jemputan, setJemputan] = useState("");
  const [totalPiutang, setTotalPiutang] = useState("");
  const [tempTotalPiutang, setTempTotalPiutang] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState(""); // Input
  const [kolektors, setKolektors] = useState([]);
  const [potongan, setPotongan] = useState("0"); // Input
  const [totalBayar, setTotalBayar] = useState("");
  const [tempTotalBayar, setTempTotalBayar] = useState("");
  const [bayar, setBayar] = useState(""); // Input
  const [bayarDenda, setBayarDenda] = useState("");
  const [keterangan, setKeterangan] = useState(""); // Input.

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermJual, setSearchTermJual] = useState("");
  const [openJual, setOpenJual] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermJual(query);
  };

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const handleClickOpenPasswordPotongan = () => {
    setOpenPasswordPotongan(true);
  };

  const classes = useStyles();

  const handleClickOpenJual = () => {
    setOpenJual(true);
  };

  const handleCloseJual = () => {
    setOpenJual(false);
  };

  const kolektorOptions = kolektors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function dhm(t) {
    var cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      d = Math.floor(t / cd),
      h = Math.floor((t - d * cd) / ch),
      m = Math.round((t - d * cd - h * ch) / 60000),
      pad = function (n) {
        return n < 10 ? "0" + n : n;
      };
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    return d;
  }

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getCOAKasir();
    getCOAsData();
    increaseNoKwt();
    getSurveyor();
  }, []);

  useEffect(() => {
    getJual();
  }, [page, searchTermJual]);

  const getCOAKasir = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAByKode`, {
      kodeCOA: user.coaKasir,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setNamaCOA(allCOAs.data.namaCOA);
    setLoading(false);
  };

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const increaseNoKwt = async () => {
    const response = await axios.post(`${tempUrl}/findUser/${user._id}`, {
      id: user._id,
      token: user.token,
    });
    let tempNoTerakhir = response.data.noTerakhir;
    tempNoTerakhir++;
    setNoKwitansi(noKwitansi + tempNoTerakhir);
    setNoTerakhir(tempNoTerakhir);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(allSurveyors.data);
    setLoading(false);
  };

  const getJual = async () => {
    const allJualsForAngsuran = await axios.post(
      `${tempUrl}/jualsForAngsuranBedaCabangPagination?search_query=${searchTermJual}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setJuals(allJualsForAngsuran.data.juals);
    setPage(allJualsForAngsuran.data.page);
    setPages(allJualsForAngsuran.data.totalPage);
    setRows(allJualsForAngsuran.data.totalRows);
  };

  const getAngsuranKe = async (noKontrak, kodeCabang) => {
    const angsuranBefore = await axios.post(`${tempUrl}/angsuransChildBefore`, {
      noJual: noKontrak,
      id: user._id,
      token: user.token,
      kodeCabang,
    });
    let tempAng = 0;
    if (angsuranBefore.data.hutangDenda > 0) {
      tempAng = angsuranBefore.data.hutangDenda;
      setHutangDendaBefore(angsuranBefore.data.hutangDenda);
    } else {
      setHutangDendaBefore(0);
    }
    const angsuran = await axios.post(`${tempUrl}/angsuransByNoJual`, {
      noJual: noKontrak,
      id: user._id,
      token: user.token,
      kodeCabang,
    });
    setDari(angsuran.data.tenor);
    setParentId(angsuran.data._id);
    const response = await axios.post(`${tempUrl}/angsuransChild`, {
      noJual: noKontrak,
      id: user._id,
      token: user.token,
      kodeCabang,
    });
    const responseNext = await axios.post(`${tempUrl}/angsuransChildNext`, {
      noJual: noKontrak,
      id: user._id,
      token: user.token,
      kodeCabang,
    });
    setTglJatuhTempoNext(responseNext.data.tglJatuhTempo);
    setNoAngsuran(response.data._id);
    setTglJatuhTempo(response.data.tglJatuhTempo);
    setTglJatuhTempoFormatted(formatDate(response.data.tglJatuhTempo));
    setAngModal(response.data.angModal);
    setAngBunga(response.data.angBunga);
    setAngPerBulan(response.data.angPerBulan);
    setDenda(response.data.denda);
    setHutangDenda(response.data.hutangDenda);
    setBiayaTarik(response.data.biayaTarik);
    setJemputan(response.data.jemputan);
    if (response.data.suratPenarikan) {
      setSuratPenarikanId(response.data.suratPenarikan._id);
      if (response.data.suratPenarikan.kodeKolektor) {
        setSuratPenarikanKolektor(
          `${response.data.suratPenarikan.kodeKolektor.kodeKolektor} - ${response.data.suratPenarikan.kodeKolektor.namaKolektor}`
        );
      }
    } else {
      setSuratPenarikanKolektor("");
    }

    let resultBayar =
      response.data.angPerBulan +
      response.data.denda +
      response.data.hutangDenda +
      response.data.biayaTarik +
      tempAng;

    setTotalPiutang(resultBayar);
    setTempTotalPiutang(resultBayar);
    setTotalBayar(resultBayar);
    setBayar(resultBayar.toLocaleString("en-US"));
    setBayarDenda(
      resultBayar -
        response.data.angPerBulan -
        response.data.biayaTarik -
        response.data.jemputan
    );
    setTempTotalBayar(resultBayar);

    let tglBayar =
      inputTglBayar.getFullYear() +
      "-" +
      (inputTglBayar.getMonth() + 1) +
      "-" +
      inputTglBayar.getDate();

    var d1 = new Date(tglBayar); //"now"
    d1.setHours(0, 0, 0, 0);
    var d2 = new Date(response.data.tglJatuhTempo); // some date
    d2.setHours(0, 0, 0, 0);
    var diff = Math.abs(d1 - d2); // difference in milliseconds
    var total = dhm(diff);
    if (d1 > d2) {
      setKeterlambatan(total);
    } else {
      setKeterlambatan(0);
    }
    if (d1 > d2 && total > setting.toleransiSetting) {
      setDenda(
        ((setting.dendaPersenSetting * response.data.angPerBulan) / 100) * total
      );

      resultBayar =
        response.data.angPerBulan +
        response.data.denda +
        response.data.hutangDenda +
        response.data.biayaTarik +
        tempAng +
        ((setting.dendaPersenSetting * response.data.angPerBulan) / 100) *
          total;

      setTotalPiutang(resultBayar);
      setTotalBayar(resultBayar);
      setBayar(resultBayar.toLocaleString("en-US"));
      setBayarDenda(
        resultBayar -
          response.data.angPerBulan -
          response.data.biayaTarik -
          response.data.jemputan
      );
    } else {
      setDenda(0);

      resultBayar =
        response.data.angPerBulan +
        response.data.denda +
        response.data.hutangDenda +
        response.data.biayaTarik +
        tempAng;

      setTotalPiutang(resultBayar);
      setTotalBayar(resultBayar);
      setBayarDenda(
        resultBayar -
          response.data.angPerBulan -
          response.data.biayaTarik -
          response.data.jemputan
      );
    }
  };

  const saveAngsuran = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    if (passwordPotongan !== setting.passwordPotonganSetting) {
      if (
        user.tipeUser !== "MGR" &&
        potongan.replace(/,/g, "") > setting.maxPotonganSetting
      ) {
        setOpenPasswordPotongan(true);
        return;
      }
    }

    let tglBayar =
      inputTglBayar?.getFullYear() +
      "-" +
      (inputTglBayar?.getMonth() + 1) +
      "-" +
      inputTglBayar?.getDate();

    let isFailedValidation =
      noJual.length === 0 ||
      inputTglBayar === null ||
      bayar.length === 0 ||
      kodeCOA.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else if (bayar.replace(/,/g, "") < angPerBulan + biayaTarik + jemputan) {
      alert(
        `Bayar tidak boleh kurang dari Angsuran / Bulan : ${
          angPerBulan + biayaTarik + jemputan
        }`
      );
    } else {
      try {
        setLoading(true);
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        let tempKolektor;
        if (kodeKolektor.length === 0) {
          tempKolektor = await axios.post(`${tempUrl}/surveyorByKode`, {
            kodeSurveyor: kodeKolektor.split(" ", 1)[0],
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
        }
        if (suratPenarikanId) {
          // Update Surat Penarikan
          await axios.post(`${tempUrl}/updateSt/${suratPenarikanId}`, {
            isBayar: true,
            tglUpdate: current_date,
            jamUpdate: current_time,
            userUpdate: user.username,
            id: user._id,
            token: user.token,
          });
        }
        // Update Angsuran Beda Cabang
        await axios.post(`${tempUrl}/updateAngsuranBedaCabang/${parentId}`, {
          isBayarBedaCabang: true,
          COA: tempCOA.data._id,
          angsuranKe: noAngsuran - 1,
          _id: noAngsuran,
          tglJatuhTempo,
          angModal,
          angBunga,
          angPerBulan,
          tglBayar,
          kodeSurveyor:
            kodeKolektor.length !== 0 ? tempKolektor.data._id : null,
          noKwitansi,
          keterangan,
          denda,
          potongan: potongan ? potongan.replace(/,/g, "") : 0,
          jemputan,
          biayaTarik,
          hutangDenda,
          totalPiutang,
          totalBayar,
          bayarDenda,
          bayar: bayar.replace(/,/g, ""),
          userInput: user.username,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          id: user._id,
          token: user.token,
          kodeCabangBayar: user.cabang._id,
        });
        await axios.post(`${tempUrl}/updateAngsuranBayarKe/${parentId}`, {
          bayarKe: noAngsuran + 1,
          id: user._id,
          token: user.token,
        });
        await axios.post(`${tempUrl}/updateJual/${jualId}`, {
          isSweeping: false,
          isMotorHilang: false,
          isTitip: false,
          sisaBulan: dari - noAngsuran,
          tglJatuhTempo: tglJatuhTempoNext,
          id: user._id,
          token: user.token,
        });
        // Update No Kwitansi User
        let updateUser = await axios.post(
          `${tempUrl}/updateUserThenLogin/${user._id}`,
          {
            noTerakhir,
            id: user._id,
            token: user.token,
          }
        );
        const findSetting = await axios.post(`${tempUrl}/lastSetting`, {
          kodeCabang: updateUser.data.details.cabang._id,
          id: updateUser.data.details._id,
          token: updateUser.data.details.token,
        });
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: updateUser.data.details,
          setting: findSetting.data,
        });
        // setLoading(false);
        navigate("/daftarAngsuranBedaCabang");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Entry Angsuran Beda Cabang
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveAngsuran}>
          {/* Data Penjualan */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PENJUALAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Kwitansi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKwitansi} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noJual}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenJual()}
                          onChange={(e) =>
                            setNoJual(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Jual harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Pembeli */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PEMBELI
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Rumah :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={almRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKtpRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tanggalJual} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kecamatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKecamatan} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Surveyor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeSurveyor} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Marketing :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeMarketing} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Motor */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA MOTOR
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tipe} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noMesin} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Warna :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaWarna} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun Perakitan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tahun} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Rincian Harga (Input) */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              ENTRY ANGSURAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran Ke- / Dari :
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control value={noAngsuran} disabled />
                      </Col>
                      <Col sm={4}>
                        <Form.Control value={dari} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Jatuh Tempo :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglJatuhTempoFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Bayar <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(user.periode.periodeAwal)}
                          maxDate={new Date(user.periode.periodeAkhir)}
                          selected={inputTglBayar}
                          onChange={(e) => {
                            setInputTglBayar(e);

                            var d1 = new Date(e); //"now"
                            var d2 = new Date(tglJatuhTempo); // some date
                            var diff = Math.abs(d1 - d2); // difference in milliseconds
                            var total = dhm(diff);
                            if (d1 > d2) {
                              setKeterlambatan(total);
                            } else {
                              setKeterlambatan(0);
                            }
                            if (d1 > d2 && total > setting.toleransiSetting) {
                              setDenda(
                                ((setting.dendaPersenSetting * angPerBulan) /
                                  100) *
                                  total
                              );
                              setTotalPiutang(
                                tempTotalPiutang +
                                  ((setting.dendaPersenSetting * angPerBulan) /
                                    100) *
                                    total
                              );
                              setTotalBayar(
                                tempTotalBayar +
                                  ((setting.dendaPersenSetting * angPerBulan) /
                                    100) *
                                    total
                              );
                              setBayar(
                                (
                                  tempTotalBayar +
                                  ((setting.dendaPersenSetting * angPerBulan) /
                                    100) *
                                    total
                                ).toLocaleString("en-US")
                              );
                            } else {
                              setDenda(0);
                              setTotalPiutang(tempTotalPiutang);
                              setTotalBayar(tempTotalBayar);
                              setBayar(tempTotalBayar.toLocaleString("en-US"));
                            }
                          }}
                          customInput={
                            <TextField
                              error={error && inputTglBayar === null && true}
                              helperText={
                                error &&
                                inputTglBayar === null &&
                                "Tanggal Bayar harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterlambatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={`${keterlambatan.toLocaleString(
                            "en-US"
                          )} hari`}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran Modal :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={angModal.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran Bunga :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={angBunga.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran / Bulan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={angPerBulan.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Denda :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={denda.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Hutang Denda (lama) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={hutangDendaBefore.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Biaya Tarik :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          value={biayaTarik.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                      <Col sm="5">
                        <Form.Control value={suratPenarikanKolektor} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jemputan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jemputan.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalPiutang.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Potongan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={potongan}
                          required
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setPotongan(tempNum);
                            setTotalBayar(
                              totalPiutang - e.target.value.replace(/,/g, "")
                            );

                            let tempBayarDenda =
                              bayar.replace(/,/g, "") -
                              angPerBulan -
                              biayaTarik -
                              jemputan;

                            let tempHutangDenda = parseInt(
                              hutangDendaBefore +
                                denda -
                                parseInt(e.target.value.replace(/,/g, "")) -
                                tempBayarDenda
                            );

                            if (tempHutangDenda) {
                              setHutangDenda(tempHutangDenda);
                            } else {
                              setHutangDenda(0);
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Potongan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Bayar :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalBayar.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bayar <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bayar}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setBayar(tempNum);

                            let tempBayarDenda =
                              tempNum.replace(/,/g, "") -
                              angPerBulan -
                              biayaTarik -
                              jemputan;

                            let tempHutangDenda = parseInt(
                              hutangDendaBefore +
                                denda -
                                potongan.replace(/,/g, "") -
                                tempBayarDenda
                            );

                            if (tempHutangDenda <= 0) {
                              setHutangDenda(0);
                              setBayarDenda(tempBayarDenda);
                            } else {
                              setBayarDenda(tempBayarDenda);
                            }

                            if (tempHutangDenda) {
                              setHutangDenda(tempHutangDenda);
                            } else {
                              setHutangDenda(0);
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bayar Denda :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={bayarDenda.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Hutang Denda (baru) :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={hutangDenda.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kolektor :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kolektorOptions}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          onInputChange={(e, value) => {
                            setKodeKolektor(value);
                            if (value.length > 0 && kodeKolektor.length === 0) {
                              setJemputan(setting.jemputanSetting);
                              setTotalPiutang(
                                totalPiutang + setting.jemputanSetting
                              );
                              setTotalBayar(
                                totalBayar + setting.jemputanSetting
                              );
                              setBayar(
                                (
                                  parseInt(bayar.replace(/,/g, "")) +
                                  setting.jemputanSetting
                                ).toLocaleString("en-US")
                              );
                            }
                            if (value.length === 0) {
                              setJemputan(0);
                              setTotalPiutang(
                                totalPiutang - setting.jemputanSetting
                              );
                              setTotalBayar(
                                totalBayar - setting.jemputanSetting
                              );
                              setBayar(
                                (
                                  parseInt(bayar.replace(/,/g, "")) -
                                  setting.jemputanSetting
                                ).toLocaleString("en-US")
                              );
                            }
                          }}
                          disabled={noJual.length === 0}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. COA <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={`${kodeCOA} - ${namaCOA}`}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenCOA()}
                        />
                        <Form.Control.Feedback type="invalid">
                          No. COA harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keterangan}
                          onChange={(e) =>
                            setKeterangan(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/daftarAngsuranBedaCabang")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={saveAngsuran}
            >
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openJual}
        onClose={handleCloseJual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jual`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Plat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Alamat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {juals.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setJualId(user._id);
                        setNoJual(user.noJual);
                        setNoKtpRegister(user.noKtpRegister);
                        setNamaRegister(user.namaRegister);
                        setAlmRegister(user.almRegister);
                        setTanggalJual(formatDate(user.tanggalJual));
                        setKodeKecamatan(
                          `${user.kodeKecamatan.kodeKecamatan} - ${user.kodeKecamatan.namaKecamatan}`
                        );
                        setKodeSurveyor(
                          `${user.kodeSurveyor.kodeSurveyor} - ${user.kodeSurveyor.namaSurveyor}`
                        );
                        setKodeMarketing(
                          `${user.kodeMarketing.kodeMarketing} - ${user.kodeMarketing.namaMarketing}`
                        );
                        setTipe(user.tipe);
                        setNoMesin(user.noMesin);
                        setNamaWarna(user.namaWarna);
                        setTahun(user.tahun);
                        getAngsuranKe(user.noJual, user.cabang._id);

                        handleCloseJual();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noJual}
                      </TableCell>
                      <TableCell>
                        {user.tenor - user.bayarKe !== 0 ? "MASIH" : "SELESAI"}
                      </TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                      <TableCell>{user.tanggalJualFormatted}</TableCell>
                      <TableCell>{user.nopol}</TableCell>
                      <TableCell>{user.almRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          setNamaCOA(user.namaCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openpasswordPotongan}
        onClose={handleClickOpenPasswordPotongan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Potongan melebihi ${setting.maxPotonganSetting.toLocaleString(
          "en-US"
        )}. Masukkan Password Potongan:`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Password Potongan <b style={colorRed}>*</b> :
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="password"
                  value={passwordPotongan}
                  onChange={(e) =>
                    setPasswordPotongan(e.target.value.toUpperCase())
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Password Potongan harus diisi!
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Box>
        </DialogActions>
        <Box sx={{ display: "flex", padding: "20px" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenPasswordPotongan(false)}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={(e) => {
              if (passwordPotongan !== setting.passwordPotonganSetting) {
                alert("Password salah!");
                return;
              } else {
                saveAngsuran(e);
              }
            }}
          >
            Simpan
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default TambahAngsuranBedaCabang;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
