import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { ShowTablePeriode } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ScrollToTop } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { Col, Row, Form } from "react-bootstrap";

const GantiPeriode = () => {
  const { setting, user } = useContext(AuthContext);
  const { error, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [isPeriodeExist, setIsPeriodeExist] = useState(false);
  const [periodeAwal, setPeriodeAwal] = useState("");
  const [periodeAkhir, setPeriodeAkhir] = useState("");
  const [namaPeriode, setNamaPeriode] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [periodesData, setPeriodesData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getPeriodeById() : setIsPeriodeExist(false);
  }, [id]);

  useEffect(() => {
    getPeriodesData();
  }, [page, searchTerm]);

  const getPeriodesData = async () => {
    setLoading(true);
    try {
      const allPeriode = await axios.post(
        `${tempUrl}/tutupPeriodesPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setQuery(searchTerm);
      setPeriodesData(allPeriode.data.tutupPeriodes);
      setPage(allPeriode.data.page);
      setPages(allPeriode.data.totalPage);
      setRows(allPeriode.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPeriodeById = async () => {
    setLoading(true);
    if (id) {
      const pickedPeriode = await axios.post(`${tempUrl}/tutupPeriodes/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setIsPeriodeExist(true);
      setPeriodeAwal(formatDate(pickedPeriode.data.periodeAwal));
      setPeriodeAkhir(formatDate(pickedPeriode.data.periodeAkhir));
      setNamaPeriode(pickedPeriode.data.namaPeriode);
    }
    setLoading(false);
  };

  const gantiPeriode = async () => {
    try {
      const findSetting = await axios.post(`${tempUrl}/lastSetting`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      const pickedPeriode = await axios.post(
        `${tempUrl}/tutupPeriodeByNamaPeriode`,
        {
          namaPeriode,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      const gantiPeriodeUser = await axios.post(
        `${tempUrl}/updateUserThenLogin/${user._id}`,
        {
          periode: pickedPeriode.data._id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: gantiPeriodeUser.data.details,
        setting: findSetting.data,
      });
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Accounting</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ganti Periode
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />
      {isPeriodeExist && (
        <>
          <Box sx={buttonModifierContainer}>
            <Button
              variant="contained"
              color="success"
              sx={{ bgcolor: "success.light", textTransform: "none" }}
              startIcon={<ChangeCircleIcon />}
              size="small"
              onClick={() => {
                gantiPeriode();
              }}
            >
              Ganti Periode
            </Button>
          </Box>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Awal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={periodeAwal} disabled />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Akhir :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={periodeAkhir} disabled />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama Periode :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaPeriode} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTablePeriode currentPosts={periodesData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default GantiPeriode;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
