import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const TambahCustomer = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [kodeRegister, setKodeRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [almKtpRegister, setAlmKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaIbuKandungRegister, setNamaIbuKandungRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [almPjmRegister, setAlmPjmRegister] = useState("");
  const [tlpPjmRegister, setTlpPjmRegister] = useState("");
  const [hubunganRegister, setHubunganRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [pekerjaanPenjaminId, setPekerjaanPenjaminId] = useState("");

  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [leasings, setLeasings] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.kodeKecamatan} - ${kecamatan.namaKecamatan}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const leasingOptions = leasings.map((leasing) => ({
    label: `${leasing.kodeLeasing} - ${leasing.namaLeasing}`,
  }));

  useEffect(() => {
    getNextKodeRegister();
    getMarketing();
    getSurveyor();
    getKecamatan();
    getPekerjaan();
    getLeasing();
  }, []);

  const getMarketing = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getNextKodeRegister = async () => {
    setLoading(true);
    const nextKodeRegister = await axios.post(`${tempUrl}/registersNextKode`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKodeRegister(nextKodeRegister.data);
    setLoading(false);
  };

  const getKecamatan = async () => {
    setLoading(true);
    const allKecamatans = await axios.post(`${tempUrl}/kecamatans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKecamatans(allKecamatans.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setPekerjaanPenjaminId(
      `${allPekerjaans.data[0].kodePekerjaan} - ${allPekerjaans.data[0].namaPekerjaan}`
    );
    setLoading(false);
  };

  const getLeasing = async () => {
    setLoading(true);
    const allLeasings = await axios.post(`${tempUrl}/leasings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setLeasings(allLeasings.data);
    setLoading(false);
  };

  const saveCustomer = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      namaRegister.length === 0 ||
      almRegister.length === 0 ||
      tlpRegister.length === 0 ||
      noKtpRegister.length === 0 ||
      almKtpRegister.length === 0 ||
      noKKRegister.length === 0 ||
      kodeMarketing.length === 0 ||
      kodeSurveyor.length === 0 ||
      kodePekerjaan.length === 0 ||
      kodeKecamatan.length === 0 ||
      kodeLeasing.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
          kodeMarketing,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
          kodePekerjaan: kodePekerjaan.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const findPekerjaanPenjamin = await axios.post(
          `${tempUrl}/pekerjaanByKode`,
          {
            kodePekerjaan: pekerjaanPenjaminId,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
          id: user._id,
          token: user.token,
        });
        const tempKecamatan = await axios.post(`${tempUrl}/kecamatanByKode`, {
          kodeKecamatan: kodeKecamatan.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempLeasing = await axios.post(`${tempUrl}/leasingByKode`, {
          kodeLeasing,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/saveRegister`, {
          namaRegister,
          almRegister,
          tlpRegister,
          noKtpRegister,
          almKtpRegister,
          noKKRegister,
          namaIbuKandungRegister,
          namaPjmRegister,
          almPjmRegister,
          tlpPjmRegister,
          hubunganRegister,
          noKtpPjmRegister,
          namaRefRegister,
          almRefRegister,
          tlpRefRegister,
          kecamatanId: tempKecamatan.data._id,
          pekerjaanId: tempPekerjaan.data._id,
          pekerjaanPenjaminId: findPekerjaanPenjamin.data
            ? findPekerjaanPenjamin.data._id
            : allPekerjaans.data[0]._id,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        await axios.post(`${tempUrl}/saveAndCheckCustomer`, {
          noRegister: kodeRegister,
          namaRegister,
          almRegister,
          tlpRegister,
          noKtpRegister,
          almKtpRegister,
          noKKRegister,
          namaIbuKandungRegister,
          namaPjmRegister,
          almPjmRegister,
          tlpPjmRegister,
          hubunganRegister,
          noKtpPjmRegister,
          namaRefRegister,
          almRefRegister,
          tlpRefRegister,
          kodeMarketing: tempMarketing.data._id,
          kodeSurveyor: tempSurveyor.data._id,
          kodePekerjaan: tempPekerjaan.data._id,
          ketPekerjaan,
          kodeKecamatan: tempKecamatan.data._id,
          kodeLeasing: tempLeasing.data._id,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/customer");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Customer
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveCustomer}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaRegister}
                        required
                        onChange={(e) =>
                          setNamaRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almRegister}
                        required
                        onChange={(e) =>
                          setAlmRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Register <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpRegister}
                        type="number"
                        required
                        onChange={(e) =>
                          setTlpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Telepon harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKtpRegister}
                        required
                        onChange={(e) =>
                          setNoKtpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat KTP <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almKtpRegister}
                        required
                        onChange={(e) =>
                          setAlmKtpRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Alamat KTP harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KK <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKKRegister}
                        required
                        onChange={(e) =>
                          setNoKKRegister(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        No. KK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Ibu Kandung :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaIbuKandungRegister}
                        onChange={(e) =>
                          setNamaIbuKandungRegister(
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Pekerjaan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodePekerjaan.length === 0 && true}
                            helperText={
                              error &&
                              kodePekerjaan.length === 0 &&
                              "Kode Pekerjaan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodePekerjaan(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}></Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={ketPekerjaan}
                        onChange={(e) => {
                          setKetPekerjaan(e.target.value.toUpperCase());
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Kecamatan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={kecamatanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeKecamatan.length === 0 && true}
                            helperText={
                              error &&
                              kodeKecamatan.length === 0 &&
                              "Kode Kecamatan harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeKecamatan(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Marketing <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={marketingOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeMarketing.length === 0 && true}
                            helperText={
                              error &&
                              kodeMarketing.length === 0 &&
                              "Kode Marketing harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeMarketing(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Surveyor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={surveyorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeSurveyor.length === 0 && true}
                            helperText={
                              error &&
                              kodeSurveyor.length === 0 &&
                              "Kode Surveyor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeSurveyor(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Leasing <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={leasingOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeLeasing.length === 0 && true}
                            helperText={
                              error &&
                              kodeLeasing.length === 0 &&
                              "Kode Leasing harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeLeasing(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaPjmRegister}
                        onChange={(e) =>
                          setNamaPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almPjmRegister}
                        onChange={(e) =>
                          setAlmPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="number"
                        value={tlpPjmRegister}
                        onChange={(e) =>
                          setTlpPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Hubungan Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={hubunganRegister}
                        onChange={(e) =>
                          setHubunganRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noKtpPjmRegister}
                        onChange={(e) =>
                          setNoKtpPjmRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Pekerjaan Penjamin <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={pekerjaanOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={
                              error && pekerjaanPenjaminId.length === 0 && true
                            }
                            helperText={
                              error &&
                              pekerjaanPenjaminId.length === 0 &&
                              "Pekerjaan Penjamin harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        value={{ label: pekerjaanPenjaminId }}
                        onInputChange={(e, value) =>
                          setPekerjaanPenjaminId(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={namaRefRegister}
                        onChange={(e) =>
                          setNamaRefRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={almRefRegister}
                        onChange={(e) =>
                          setAlmRefRegister(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tlpRefRegister}
                        onChange={(e) =>
                          setTlpRefRegister(e.target.value.toUpperCase())
                        }
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/customer")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahCustomer;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
