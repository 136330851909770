// formatDate function will return dd-mm-yyyy for UI Indonesia
export const formatDate = (date) => {
  if (date) {
    let tempDate = new Date(date);
    return `${tempDate.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${(tempDate.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${tempDate.getFullYear()}`;
  } else {
    return "";
  }
};

export const formatTime = (date) => {
  if (date) {
    let tempDate = new Date(date);
    return `${tempDate.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${tempDate.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${tempDate.getSeconds().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;
  } else {
    return "";
  }
};
