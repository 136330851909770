import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Chip,
} from "@mui/material";
import { ShowTableAngsuranBedaCabang } from "../../../components/ShowTable";
import { Loader, usePagination } from "../../../components";
import { Colors } from "../../../constants/styles";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col, Row, Form } from "react-bootstrap";

const TampilAngsuranBedaCabang = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const cabangAngsuran = location.pathname.split("/")[4];

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Show Data Jual
  const [namaRegister, setNamaRegister] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [nopol, setNopol] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorAsli, setKodeSurveyorAsli] = useState("");
  const [tipe, setTipe] = useState("");
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [noRegister, setNoRegister] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");
  const [modal, setModal] = useState("");
  const [bunga, setBunga] = useState("");

  const [tglSpTerakhir, setTglSpTerakhir] = useState("");
  const [tglMdTerakhir, setTglMdTerakhir] = useState("");
  const [tglStTerakhir, setTglStTerakhir] = useState("");
  const [tglPenarikan, setTglPenarikan] = useState("");
  const [spKe, setSpKe] = useState("");
  const [angsurans, setAngsurans] = useState([]);
  const [angsuranTableRekap, setAngsuranTableRekap] = useState([]);
  const [angsuranTableRinci, setAngsuranTableRinci] = useState([]);
  const navigate = useNavigate();

  const columnsRekap = [
    { title: "Tgl. Bayar", field: "tglBayarFormatted" },
    { title: "TJ. Tempo", field: "tglJatuhTempoFormatted" },
    { title: "No. Kwitansi", field: "noKwitansi" },
    { title: "Ke", field: "_id" },
    { title: "Keterangan", field: "keterangan" },
    { title: "Bayar", field: "angPerBulan" },
    { title: "Saldo", field: "saldo" },
  ];

  const columnsRinci = [
    { title: "Tgl. Bayar", field: "tglBayarFormatted" },
    { title: "TJ. Tempo", field: "tglJatuhTempoFormatted" },
    { title: "No. Kwitansi", field: "noKwitansi" },
    { title: "Ke", field: "_id" },
    { title: "A. Modal", field: "angModal" },
    { title: "Saldo", field: "modal" },
    { title: "A. Bunga", field: "angBunga" },
    { title: "Saldo", field: "bunga" },
    { title: "Bayar", field: "angPerBulan" },
    { title: "Saldo", field: "saldo" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = angsurans.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(angsurans.length / PER_PAGE);
  const _DATA = usePagination(angsurans, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id && getJualById();
  }, [id]);

  const getAngsuran = async () => {
    setLoading(true);
    const allAngsuransByNoJual = await axios.post(
      `${tempUrl}/angsuransByNoJual`,
      {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: cabangAngsuran,
      }
    );
    setAngsurans(allAngsuransByNoJual.data.angsuran);
    setModal(
      allAngsuransByNoJual.data.angsuran[0].angModal *
        allAngsuransByNoJual.data.tenor
    );
    setBunga(
      allAngsuransByNoJual.data.angsuran[0].angBunga *
        allAngsuransByNoJual.data.tenor
    );
    setLoading(false);
  };

  const getAngsuranRekap = async () => {
    setLoading(true);
    const allAngsuransByNoJualRekap = await axios.post(
      `${tempUrl}/angsuransByNoJualRekap`,
      {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: cabangAngsuran,
      }
    );
    setAngsuranTableRekap(allAngsuransByNoJualRekap.data);
    setLoading(false);
  };

  const getAngsuranRinci = async () => {
    setLoading(true);
    const allAngsuransByNoJualRinci = await axios.post(
      `${tempUrl}/angsuransByNoJualRinci`,
      {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: cabangAngsuran,
      }
    );
    setAngsuranTableRinci(allAngsuransByNoJualRinci.data);
    setLoading(false);
  };

  const getJualById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: cabangAngsuran,
      });
      if (response.data.tglAng.length !== 0) {
        getAngsuran();
        getAngsuranRekap();
        getAngsuranRinci();
      }
      setNamaRegister(response.data.namaRegister);
      setNoJual(response.data.noJual);
      setTanggalJual(formatDate(response.data.tglAng));
      setNopol(response.data.nopol);
      setAlmRegister(response.data.almRegister);
      setKodeSurveyor(response.data.kodeSurveyor);
      setKodeSurveyorAsli(response.data.kodeSurveyorAsli);
      setTipe(response.data.tipe);
      setHargaTunai(response.data.hargaTunai);
      setUangMuka(response.data.uangMuka);
      setAngPerBulan(response.data.angPerBulan);
      setTenor(response.data.tenor);
      setJumlahPiutang(response.data.jumlahPiutang);
      setTahun(response.data.tahun);
      setNamaWarna(response.data.namaWarna);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNoRegister(response.data.noRegister);
      setTglAng(response.data.tglAng);
      setTglAngFormatted(formatDate(response.data.tglAng));
      setTglAngAkhir(response.data.tglAngAkhir);
      setTglAngAkhirFormatted(formatDate(response.data.tglAngAkhir));
      setTglSpTerakhir(formatDate(response.data.tglSpTerakhir));
      setTglMdTerakhir(formatDate(response.data.tglMdTerakhir));
      setTglStTerakhir(formatDate(response.data.tglStTerakhir));
      setTglPenarikan(formatDate(response.data.tglPenarikan));
      setSpKe(response.data.spKe);
    }
  };

  const downloadPdfRekap = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Kartu Kredit Rekap`, 85, 30);
    doc.setFontSize(10);
    doc.text(`Nama               : ${namaRegister}`, 15, 40);
    doc.text(`Tipe                    : ${tipe}`, 120, 40);
    doc.text(`Alamat              : ${almRegister.substring(0, 30)}`, 15, 45);
    doc.text(`No. Rangka        : ${noRangka}`, 120, 45);
    doc.text(`Tgl. kontrak      : ${tanggalJual}`, 15, 50);
    doc.text(`No. Mesin           : ${noMesin}`, 120, 50);
    doc.text(`Harga Tunai     : ${hargaTunai.toLocaleString("en-US")}`, 15, 55);
    doc.text(`Nopol                  : ${nopol}`, 120, 55);
    doc.text(`Uang Muka       : ${uangMuka.toLocaleString("en-US")}`, 15, 60);
    doc.text(`No. Kontrak        : ${noRegister}`, 120, 60);
    doc.text(
      `Angs / Bulan    : ${angPerBulan.toLocaleString("en-US")} / ${tenor}`,
      15,
      65
    );
    doc.text(`Tgl. Angs. I         : ${tglAngFormatted}`, 120, 65);
    doc.text(
      `Jml. Piutang    : ${jumlahPiutang.toLocaleString("en-US")}`,
      15,
      70
    );
    doc.text(`Tgl. Angs. Akhir  : ${tglAngAkhirFormatted}`, 120, 70);
    doc.text(`Thn / Warna     : ${tahun} / ${namaWarna}`, 15, 75);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 5 && col <= 6) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
      columns: columnsRekap.map((col) => ({ ...col, dataKey: col.field })),
      body: angsuranTableRekap,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfRinci = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [280, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Kartu Kredit Rinci`, 120, 30);
    doc.setFontSize(10);
    doc.text(`Nama               : ${namaRegister}`, 15, 40);
    doc.text(`Tipe                    : ${tipe}`, 120, 40);
    doc.text(`Alamat              : ${almRegister.substring(0, 30)}`, 15, 45);
    doc.text(`No. Rangka        : ${noRangka}`, 120, 45);
    doc.text(`Tgl. kontrak      : ${tanggalJual}`, 15, 50);
    doc.text(`No. Mesin           : ${noMesin}`, 120, 50);
    doc.text(`Harga Tunai     : ${hargaTunai.toLocaleString("en-US")}`, 15, 55);
    doc.text(`Nopol                  : ${nopol}`, 120, 55);
    doc.text(`Uang Muka       : ${uangMuka.toLocaleString("en-US")}`, 15, 60);
    doc.text(`No. Kontrak        : ${noRegister}`, 120, 60);
    doc.text(
      `Angs / Bulan     : ${angPerBulan.toLocaleString("en-US")} / ${tenor}`,
      15,
      65
    );
    doc.text(`Tgl. Angs. I         : ${tglAngFormatted}`, 120, 65);
    doc.text(
      `Jml. Piutang     : ${jumlahPiutang.toLocaleString("en-US")}`,
      15,
      70
    );
    doc.text(`Tgl. Angs. Akhir  : ${tglAngAkhirFormatted}`, 120, 70);
    doc.text(`Thn / Warna     : ${tahun} / ${namaWarna}`, 15, 75);
    doc.text(`Modal              : ${modal.toLocaleString("en-US")}`, 15, 80);
    doc.text(`Bunga              : ${bunga.toLocaleString("en-US")}`, 15, 85);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 4 && col <= 9) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 90,
      columns: columnsRinci.map((col) => ({ ...col, dataKey: col.field })),
      body: angsuranTableRinci,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarAngsuranBedaCabang")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Angsuran Beda Cabang
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button startIcon={<PrintIcon />} onClick={() => downloadPdfRekap()}>
            Rekap
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => downloadPdfRinci()}
          >
            Rinci
          </Button>
        </ButtonGroup>
      </Box>
      <Box>
        <Divider sx={[dividerStyle, { marginBottom: 2 }]} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama Register :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noJual} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tanggalJual} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nopol :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={nopol} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tipe :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tipe} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Alamat :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={almRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    SP Ke- :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={spKe} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. SP Terakhir :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglSpTerakhir} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. MD Terakhir :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglSpTerakhir} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. ST Terakhir :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglStTerakhir} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. Penarikan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglPenarikan} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Surveyor :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeSurveyor.kodeSurveyor} - ${kodeSurveyor.namaSurveyor}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Surveyor Asli :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeSurveyorAsli.kodeSurveyor} - ${kodeSurveyorAsli.namaSurveyor}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Chip
          label="Percepatan"
          sx={{
            backgroundColor: Colors.blue100,
          }}
        />
        <Chip
          label="Percepatan Dibayar dari Cabang Lain"
          sx={{
            backgroundColor: Colors.green400,
          }}
        />
        <Chip
          label="Dibayar dari Cabang Lain"
          sx={{
            backgroundColor: Colors.yellow100,
          }}
        />
        <Chip
          label="Penarikan"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
        <Box sx={tableContainer}>
          <ShowTableAngsuranBedaCabang
            id={id}
            currentPosts={currentPosts}
            cabangAngsuran={cabangAngsuran}
          />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TampilAngsuranBedaCabang;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
