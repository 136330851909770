import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTablePemutihanChild } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import {
  Box,
  Chip,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import { Colors } from "../../../constants/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";

const TampilPemutihan = () => {
  const { setting, user } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [noBukti, setNoBukti] = useState("");
  const [tglPengajuan, setTglPengajuan] = useState("");
  const [jumlahChild, setJumlahChild] = useState("");
  const [totalSisaPiutang, setTotalSisaPiutang] = useState("");
  const [status, setStatus] = useState("");
  const [status2, setStatus2] = useState("");
  const [tglApprove, setTglApprove] = useState("");
  const [userInputApprove, setUserInputApprove] = useState("");

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [pemutihanChildData, setPemutihanChildData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "No. Kontrak", field: "noJual" },
    { title: "Nama Customer", field: "namaRegister" },
    { title: "Surveyor", field: "surveyor" },
    { title: "Hari Telat", field: "hariTelat" },
    { title: "Tanggal Pengajuan", field: "tglPengajuanFormatted" },
    { title: "Sisa Piutang", field: "sisaPiutangFormatted" },
  ];

  useEffect(() => {
    getPemutihanById();
  }, [id, value, page, searchTerm]);

  const getPemutihanById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/pemutihans/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoBukti(response.data.noBukti);
      setTglPengajuan(formatDate(response.data.tglPengajuan));
      setJumlahChild(response.data.jumlahChild);
      setTotalSisaPiutang(response.data.totalSisaPiutang);
      setStatus(
        response.data.status == "BELUMAPPROVE" ? "Belum Approve" : "Approve"
      );
      setStatus2(
        response.data.status2 == "BELUMAPPROVE" ? "Belum Approve" : "Approve"
      );
      setTglApprove(formatDate(response.data.tglApprove));
      setUserInputApprove(response.data.userInputApprove);

      let allPemutihansChild;
      switch (value) {
        case "Belum Approve":
          allPemutihansChild = await axios.post(
            `${tempUrl}/pemutihansChildPaginationBelumApproveByNoBukti?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              noBukti: response.data.noBukti,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Sudah Approve":
          allPemutihansChild = await axios.post(
            `${tempUrl}/pemutihansChildPaginationSudahApproveByNoBukti?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              noBukti: response.data.noBukti,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        default:
          allPemutihansChild = await axios.post(
            `${tempUrl}/pemutihansChildByNoBuktiPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              noBukti: response.data.noBukti,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
      }
      setQuery(searchTerm);
      setPemutihanChildData(allPemutihansChild.data.pemutihansChild);
      setPage(allPemutihansChild.data.page);
      setPages(allPemutihansChild.data.totalPage);
      setRows(allPemutihansChild.data.totalRows);
    }
  };

  const deletePemutihan = async (id) => {
    try {
      setLoading(true);

      // Delete Surat Penarikan
      await axios.post(`${tempUrl}/deletePemutihan/${id}`, {
        noBukti,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      setNoBukti("");
      setTglPengajuan("");
      setJumlahChild("");
      setTotalSisaPiutang("");
      setStatus("");
      setStatus2("");
      setTglApprove("");
      setUserInputApprove("");
      setLoading(false);
      navigate("/daftarPemutihan");
    } catch (error) {
      console.log(error);
    }
  };

  const getPemutihansForDoc = async () => {
    setLoading(true);

    let allPemutihansChild;
    switch (value) {
      case "Belum Approve":
        allPemutihansChild = await axios.post(
          `${tempUrl}/pemutihansChildBelumApproveByNoBukti`,
          {
            noBukti: noBukti,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      case "Sudah Approve":
        allPemutihansChild = await axios.post(
          `${tempUrl}/pemutihansChildSudahApproveByNoBukti`,
          {
            noBukti: noBukti,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      default:
        allPemutihansChild = await axios.post(
          `${tempUrl}/pemutihansChildByNoBukti`,
          {
            noBukti: noBukti,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
    }

    setLoading(false);
    downloadPdf(allPemutihansChild.data);
  };

  const downloadPdf = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);

    let tempY = 30;

    doc.setFontSize(16);
    doc.text(`Pemutihan`, 90, tempY);
    doc.setFontSize(10);
    tempY += 5;
    doc.text(`No. Bukti`, 15, tempY);
    doc.text(`: ${noBukti}`, 45, tempY);
    tempY += 5;
    doc.text(`Tgl Pengajuan`, 15, tempY);
    doc.text(`: ${tglPengajuan}`, 45, tempY);
    tempY += 5;
    doc.text(`Jumlah`, 15, tempY);
    doc.text(`: ${jumlahChild}`, 45, tempY);
    tempY += 5;
    doc.text(`Total Sisa Piutang`, 15, tempY);
    doc.text(`: ${totalSisaPiutang.toLocaleString("en-US")}`, 45, tempY);
    tempY += 5;
    doc.text(`Status`, 15, tempY);
    doc.text(`: ${status}`, 45, tempY);
    tempY += 5;
    doc.text(`Tgl. Approve`, 15, tempY);
    doc.text(`: ${tglApprove}`, 45, tempY);
    tempY += 5;
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    function alignCol(data) {
      var col = data.column.index;
      if (col == 4 || col == 6) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : tempY,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarPemutihan")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Pemutihan
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => {
              getPemutihansForDoc();
            }}
          >
            CETAK
          </Button>
        </ButtonGroup>
      </Box>
      {
        <Box sx={buttonModifierContainer}>
          <ButtonModifier
            id={id}
            kode={noBukti}
            addLink={`/daftarPemutihan/pemutihan/${id}/tambahPemutihan`}
            editLink={null}
            deleteUser={deletePemutihan}
            nameUser={noBukti}
          />
        </Box>
      }
      <hr />
      <Box sx={showDataContainer}>
        <Box sx={showDataWrapper}>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  No. Bukti :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={noBukti} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Tgl. Pengajuan :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={tglPengajuan} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Jumlah :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={jumlahChild.toLocaleString("en-US")}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Total Sisa Piutang :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={totalSisaPiutang.toLocaleString("en-US")}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Status :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={status} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Tgl. Approve :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={tglApprove} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  User Approve :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={userInputApprove} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Box>
        <Box sx={[showDataWrapper, secondWrapper]}></Box>
      </Box>
      <hr />
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Sudah Approve"
            control={<Radio />}
            label="Sudah Approve"
          />
          <FormControlLabel
            value="Belum Approve"
            control={<Radio />}
            label="Belum Approve"
          />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Sudah Approve"
          sx={{
            backgroundColor: Colors.green400,
          }}
        />
        <Chip
          label="Belum Approve"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTablePemutihanChild
          currentPosts={pemutihanChildData}
          idPemutihan={id}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilPemutihan;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
