import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableBeli } from "../../../components/ShowTable";
import { Loader, usePagination, ButtonModifier } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import { formatDate } from "../../../constants/helper";

const TampilBeli = () => {
  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [noBeli, setNoBeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [kodeSupplier, setKodeSupplier] = useState("");
  const [jumlahBeli, setJumlahBeli] = useState(0);
  const [ppnBeli, setPpnBeli] = useState(0);
  const [isPpnBeli, setIsPpnBeli] = useState(false);
  const [potongan, setPotongan] = useState(0);
  const [lama, setLama] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");
  const [jatuhTempo, setJatuhTempo] = useState("");
  const [isPost, setIsPost] = useState("");
  const [daftarStoksData, setDaftarStoksData] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  const [isEditable, setIsEditable] = useState(false);

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = daftarStoksData.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(daftarStoksData.length / PER_PAGE);
  const _DATA = usePagination(daftarStoksData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id && getBeliById();
  }, [id, isPpnBeli, isEditable]);

  const getBeliById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/belis/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setNoBeli(response.data.noBeli);
      setTanggalBeli(formatDate(response.data.tanggalBeli));
      setJumlahBeli(response.data.jumlahBeli);
      setKodeSupplier(response.data.supplier);
      setPpnBeli(response.data.ppnBeli);
      setIsPpnBeli(response.data.isPpnBeli);
      setPotongan(response.data.potongan);
      setLama(response.data.lama);
      setJenisBeli(response.data.jenisBeli);
      setJatuhTempo(formatDate(response.data.jatuhTempo));
      setIsPost(response.data.isPost);
      let tglBeliDate = new Date(response.data.tanggalBeli);

      const response2 = await axios.post(`${tempUrl}/daftarStoksByNoBeli`, {
        noBeli: response.data.noBeli,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setDaftarStoksData(response2.data);

      let isEditableManager =
        user.tipeUser !== "ADM" && response.data.isPost === false;
      let isEditableAdmin =
        user.tipeUser === "ADM" &&
        tglBeliDate?.getFullYear() === today.getFullYear() &&
        tglBeliDate?.getMonth() === today.getMonth() &&
        tglBeliDate?.getDate() === today.getDate() &&
        response.data.isPost === false;
      setIsEditable(isEditableManager || isEditableAdmin);
    }
  };

  const deleteBeli = async (id) => {
    try {
      setLoading(true);
      for (let daftarStok of daftarStoksData) {
        await axios.post(`${tempUrl}/deleteDaftarStok/${daftarStok._id}`, {
          id: user._id,
          token: user.token,
        });
      }
      await axios.post(`${tempUrl}/deleteBeli/${id}`, {
        id: user._id,
        token: user.token,
      });
      setLoading(false);
      navigate("/daftarBeli");
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsPpn = async () => {
    setIsPpnBeli(!isPpnBeli);
    await axios.post(`${tempUrl}/updateBeli/${id}`, {
      isPpnBeli: !isPpnBeli,
      id: user._id,
      token: user.token,
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarBeli")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Pembelian</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Beli
        </Typography>
        {isEditable && (
          <Box sx={buttonModifierContainer}>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarBeli/beli/${id}/tambahBeliChild`}
              editLink={`/daftarBeli/beli/${id}/edit`}
              deleteUser={deleteBeli}
              nameUser={noBeli}
            />
          </Box>
        )}
        <Divider sx={dividerStyle} />
        <Box sx={textFieldContainer}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isPpnBeli} />}
              label="PPN"
              onChange={() => changeIsPpn()}
            />
          </FormGroup>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nomor :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noBeli} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jenis Motor :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={jenisBeli.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Supplier :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeSupplier.kodeSupplier} - ${kodeSupplier.namaSupplier}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jumlah :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={jumlahBeli.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {isPpnBeli && (
                <>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        PPN :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={ppnBeli.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Potongan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={potongan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Lama :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${lama.toLocaleString("en-US")} hari`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tanggalBeli} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jatuh Tempo :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={jatuhTempo.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={tableContainer}>
          <ShowTableBeli
            id={id}
            currentPosts={currentPosts}
            nomorNota={noBeli}
          />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TampilBeli;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
