import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  Divider,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const CariTunggakan = () => {
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  var date = new Date();
  const [cabangInput, setCabangInput] = useState("");
  const [inputPerTanggal, setInputPerTanggal] = useState(date);
  const [lebihDari, setLebihDari] = useState("");
  const [sampaiDengan, setSampaiDengan] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [filterJenisStruk, setFilterJenisStruk] = useState("");
  const [allCabang, setAllCabang] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [tunggakan, setTunggakan] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "No. Jual", field: "noJual" },
    { title: "Nama", field: "namaRegister" },
    { title: "Alm", field: "almRegister" },
    { title: "Tlp", field: "tlpRegister" },
    { title: "Tipe", field: "tipe" },
    { title: "Nopol", field: "nopol" },
    { title: "Thn", field: "tahun" },
    { title: "Ang", field: "angPerBulan" },
    { title: "Tenor", field: "tenor" },
    { title: "Sisa Bln", field: "sisaBulan" },
    { title: "JTO", field: "tglJatuhTempo" },
    { title: "Marketing", field: "marketing" },
    { title: "Surveyor", field: "surveyor" },
    { title: "Surveyor Asli", field: "surveyorAsli" },
    { title: "MD1", field: "tglMd1" },
    { title: "SP", field: "tglSpTerakhir" },
    { title: "ST", field: "tglStTerakhir" },
    { title: "HR", field: "hr" },
    { title: "Denda", field: "dendaPerHari" },
    { title: "Titip", field: "titip" },
    { title: "Sweeping", field: "sweeping" },
  ];

  const marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  const filterJenisStrukOption = [
    { label: "KM" },
    { label: "KU" },
    { label: "KL" },
  ];

  useEffect(() => {
    if (user.tipeUser === "OWN") {
      getMarketingAllCabang();
      getSurveyorAll();
      getAllCabang();
    } else if (user.tipeUser === "MAR") {
      getMarketingByKodeMarketing(user.kodeMarketing);
      getSurveyor();
      setCabangInput(user.cabang._id);
    } else if (user.tipeUser === "SUR") {
      getMarketing();
      getSurveyorByKodeSurveyor(user.kodeSurveyor);
      setCabangInput(user.cabang._id);
    } else {
      getMarketing();
      getSurveyor();
      setCabangInput(user.cabang._id);
    }
    setSampaiDengan("0");
    setLebihDari("0");
  }, []);

  const getAllCabang = async () => {
    setLoading(true);

    let kodeCabangDiKunci = "";

    for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
      if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
        kodeCabangDiKunci =
          setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
      }
    }

    const allCabang = await axios.post(`${tempUrl}/cabangsExclude`, {
      id: user._id,
      token: user.token,
      kodeCabangDiKunci: kodeCabangDiKunci,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const getMarketing = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(response.data);
    setLoading(false);
  };

  const getMarketingByKodeMarketing = async (id) => {
    setLoading(true);
    const response = await axios.post(
      `${tempUrl}/marketingByKodeMarketing/${id}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setKodeMarketing(`${response.data[0].kodeMarketing}`);
    setLoading(false);
  };

  const getMarketingAllCabang = async () => {
    setLoading(true);

    let kodeCabangDiKunci = "";

    for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
      if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
        kodeCabangDiKunci =
          setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
      }
    }

    const allMarketings = await axios.post(`${tempUrl}/marketingsAllCabang`, {
      id: user._id,
      token: user.token,
      kodeCabangDiKunci: kodeCabangDiKunci,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(response.data);
    setLoading(false);
  };

  const getSurveyorByKodeSurveyor = async (id) => {
    setLoading(true);
    const response = await axios.post(
      `${tempUrl}/surveyorByKodeSurveyor/${id}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setKodeSurveyor(`${response.data[0].kodeSurveyor}`);
    setLoading(false);
  };

  const getSurveyorAll = async () => {
    setLoading(true);

    let kodeCabangDiKunci = "";

    for (let i = 0; i < setting.userOwnerTidakBisaAksesMcm.length; i++) {
      if (setting.userOwnerTidakBisaAksesMcm[i].username === user.username) {
        kodeCabangDiKunci =
          setting.userOwnerTidakBisaAksesMcm[i].kodeCabangDiKunci;
      }
    }

    const allSurveyors = await axios.post(`${tempUrl}/surveyorsAllCabang`, {
      id: user._id,
      token: user.token,
      kodeCabangDiKunci: kodeCabangDiKunci,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const downloadPdf = async () => {
    setLoading(true);

    let tempPersenLoading = parseInt(persenLoading);
    let perTanggal = formatDate(inputPerTanggal);
    const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
      kodeMarketing,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    tempPersenLoading += 30;
    setPersenLoading(tempPersenLoading.toString());

    const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
      kodeSurveyor,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    tempPersenLoading += 30;
    setPersenLoading(tempPersenLoading.toString());

    const response = await axios.post(`${tempUrl}/jualsForTunggakan`, {
      perTanggal: inputPerTanggal,
      lebihDari,
      sampaiDengan,
      kodeMarketing: tempMarketing.data ? tempMarketing.data._id : null,
      kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
      filterJenisStruk,
      id: user._id,
      token: user.token,
      kodeCabang: cabangInput,
    });
    tempPersenLoading += 30;
    setPersenLoading(tempPersenLoading.toString());

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [490, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Tunggakan`, 200, 30);
    doc.setFontSize(10);
    doc.text(`Per Tanggal : ${perTanggal}`, 15, 35);
    doc.text(
      `Cabang : ${cabangInput === "" ? "SEMUA CABANG" : cabangInput}`,
      15,
      40
    );
    doc.text(
      `Marketing : ${
        kodeMarketing === ""
          ? "SEMUA MARKETING"
          : `${kodeMarketing} - ${tempMarketing.data.namaMarketing}`
      }`,
      15,
      45
    );
    doc.text(
      `Surveyor : ${
        kodeSurveyor === ""
          ? "SEMUA SURVEYOR"
          : `${kodeSurveyor} - ${tempSurveyor.data.namaSurveyor}`
      }`,
      15,
      50
    );
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 55,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw === "Sweeping") {
          for (let i = 0; i <= 19; i++) {
            data.row.cells[`${i}`].styles.fillColor = Colors.yellow400;
          }
        }
        if (data.section === "body" && data.cell.raw === "Titip") {
          for (let i = 0; i <= 19; i++) {
            data.row.cells[`${i}`].styles.fillColor = Colors.red400;
          }
        }
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 30 },
        2: { cellWidth: 35 },
        3: { cellWidth: 45 },
        4: { cellWidth: 20 },
        5: { cellWidth: 25 },
        6: { cellWidth: 25 },
        7: { cellWidth: 15 },
        8: { cellWidth: 20 },
        9: { cellWidth: 10 },
        10: { cellWidth: 10 },
        11: { cellWidth: 25 },
        12: { cellWidth: 20 },
        13: { cellWidth: 20 },
        14: { cellWidth: 20 },
        15: { cellWidth: 25 },
        16: { cellWidth: 25 },
        17: { cellWidth: 25 },
        18: { cellWidth: 15 },
        19: { cellWidth: 15 },
        20: { cellWidth: 15 },
        21: { cellWidth: 15 },
        // etc
      },
    });
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  const getTunggakan = async () => {
    setLoading(true);
    let perTanggal =
      inputPerTanggal?.getFullYear() +
      "-" +
      (inputPerTanggal?.getMonth() + 1) +
      "-" +
      inputPerTanggal?.getDate();
    const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
      kodeMarketing,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
      kodeSurveyor,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    const response = await axios
      .post(`${tempUrl}/jualsForTunggakan`, {
        perTanggal,
        lebihDari,
        sampaiDengan,
        kodeMarketing: tempMarketing.data ? tempMarketing.data._id : null,
        kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
        filterJenisStruk,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      })
      .then((response) => {
        setTunggakan(response.data);
        setLoading(false);
      });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Laporan Tunggakan",
    sheet: "LaporanTunggakan",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Tunggakan
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              {user.tipeUser === "OWN" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && cabangInput.length === 0 && true}
                        helperText={
                          error &&
                          cabangInput.length === 0 &&
                          "Cabang harus diisi!"
                        }
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setCabangInput(value.split(" ", 1)[0]);
                    }}
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={cabangInput} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputPerTanggal}
                  onChange={(e) => setInputPerTanggal(e)}
                  customInput={
                    <TextField
                      error={error && inputPerTanggal === null && true}
                      helperText={
                        error &&
                        inputPerTanggal === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Kode Marketing :
              </Form.Label>
              {user.tipeUser !== "MAR" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && kodeMarketing.length === 0 && true}
                        helperText={
                          error &&
                          kodeMarketing.length === 0 &&
                          "Kode Marketing harus diisi!"
                        }
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) =>
                      setKodeMarketing(value.split(" ", 1)[0])
                    }
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={kodeMarketing} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Kode Surveyor :
              </Form.Label>
              {user.tipeUser !== "SUR" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={surveyorOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && kodeSurveyor.length === 0 && true}
                        helperText={
                          error &&
                          kodeSurveyor.length === 0 &&
                          "Kode Surveyor harus diisi!"
                        }
                        placeholder="SEMUA SURVEYOR"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) =>
                      setKodeSurveyor(value.split(" ", 1)[0])
                    }
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={kodeSurveyor} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Filter :
              </Form.Label>
              <Col sm="9">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={filterJenisStrukOption}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && filterJenisStruk.length === 0 && true}
                      helperText={
                        error &&
                        filterJenisStruk.length === 0 &&
                        "Filter harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) => setFilterJenisStruk(value)}
                  value={filterJenisStruk}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Lebih Dari :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  required
                  value={lebihDari}
                  onChange={(e) => {
                    setLebihDari(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Dengan :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  required
                  value={sampaiDengan}
                  onChange={(e) => {
                    setSampaiDengan(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => {
              getTunggakan();
            }}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      {tunggakan.length > 0 && (
        <>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DownloadIcon />}
            sx={{ marginTop: "10px" }}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        </>
      )}
      <table
        ref={tableRef}
        style={{ visibility: tunggakan.length > 0 ? "visible" : "hidden" }}
      >
        <tbody>
          <tr>
            <th>Laporan Tunggakan</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>No</th>
            <th>No. Jual</th>
            <th>Nama</th>
            <th>Alamat</th>
            <th>Telepon</th>
            <th>Tipe</th>
            <th>Angsuran</th>
            <th>Surveyor</th>
            <th>Tenor</th>
            <th>Sisa Bulan</th>
            <th>Jatuh Tempo</th>
            <th>MD1</th>
            <th>MD2</th>
            <th>MD3</th>
            <th>SP</th>
            <th>ST</th>
            <th>HR</th>
          </tr>
          {tunggakan.map((item, i) => (
            <tr>
              <th>{item.no}</th>
              <th>{item.noJual}</th>
              <th>{item.namaRegister}</th>
              <th>{item.almRegister}</th>
              <th>{item.tlpRegister}</th>
              <th>{item.tipe}</th>
              <th>{item.angPerBulan}</th>
              <th>{item.kodeSurveyor}</th>
              <th>{item.tenor}</th>
              <th>{item.sisaBulan}</th>
              <th>{item.tglJatuhTempo}</th>
              <th>{item.tglMd1}</th>
              <th>{item.tglMd2}</th>
              <th>{item.tglMd3}</th>
              <th>{item.tglSpTerakhir}</th>
              <th>{item.tglStTerakhir}</th>
              <th>{item.hr}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default CariTunggakan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};
