import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const UbahBiayaPerawatan = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [noBukti, setNoBukti] = useState("");
  const [nopol, setNopol] = useState("");
  const [inputTglPerawatan, setInputTglPerawatan] = useState(
    new Date(user.periode.periodeAwal)
  );
  const [keterangan, setKeterangan] = useState("");
  const [biayaPerawatan, setBiayaPerawatan] = useState("");
  const [biayaPerawatanLama, setBiayaPerawatanLama] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");

  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);

  // Data Motor -> Dari Stok
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [minDate, setMinDate] = useState(new Date(user.periode.periodeAwal));

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getCOAsData();
    getBiayaPerawatanById();
  }, []);

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const getBiayaPerawatanById = async () => {
    setLoading(true);
    const pickedBiayaPerawatan = await axios.post(
      `${tempUrl}/biayaPerawatans/${id}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setNoBukti(pickedBiayaPerawatan.data.noBukti);
    setNopol(pickedBiayaPerawatan.data.nopol);
    setInputTglPerawatan(new Date(pickedBiayaPerawatan.data.tglPerawatan));
    setKeterangan(pickedBiayaPerawatan.data.keterangan);
    setBiayaPerawatan(
      pickedBiayaPerawatan.data.biayaPerawatan.toLocaleString("en-US")
    );
    setBiayaPerawatanLama(
      pickedBiayaPerawatan.data.biayaPerawatan.toLocaleString("en-US")
    );
    setKodeCOA(pickedBiayaPerawatan.data.COA.kodeCOA);

    if (pickedBiayaPerawatan.data.isPost) {
      navigate(`/biayaPerawatan/${id}`);
    }

    const allDaftarStoksByNopol = await axios.post(
      `${tempUrl}/daftarStoksByNopol`,
      {
        nopol: pickedBiayaPerawatan.data.nopol,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    if (allDaftarStoksByNopol.data) {
      setNoRangka(allDaftarStoksByNopol.data.noRangka);
      setNoMesin(allDaftarStoksByNopol.data.noMesin);
      setTipe(allDaftarStoksByNopol.data.tipe);
      setNamaWarna(allDaftarStoksByNopol.data.namaWarna);
      setTahun(allDaftarStoksByNopol.data.tahun);
      setJenisBeli(allDaftarStoksByNopol.data.jenisBeli);
    }

    setLoading(false);
  };

  const updateBiayaPerawatan = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let tglPerawatan =
      inputTglPerawatan?.getFullYear() +
      "-" +
      (inputTglPerawatan?.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      inputTglPerawatan?.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

    let tempTotalBiayaPerawatan = 0;
    let isFailedValidation =
      inputTglPerawatan === null ||
      biayaPerawatan.length === 0 ||
      kodeCOA.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        let findDaftarStok = await axios.post(`${tempUrl}/daftarStoksByNopol`, {
          nopol,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        tempTotalBiayaPerawatan =
          parseInt(findDaftarStok.data.totalBiayaPerawatan) -
          parseInt(biayaPerawatanLama.replace(/,/g, "")) +
          parseInt(biayaPerawatan.replace(/,/g, ""));
        await axios.post(
          `${tempUrl}/updateDaftarStok/${findDaftarStok.data._id}`,
          {
            totalBiayaPerawatan: tempTotalBiayaPerawatan,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        await axios.post(`${tempUrl}/updateBiayaPerawatan/${id}`, {
          COA: tempCOA.data._id,
          tglPerawatan,
          keterangan,
          biayaPerawatan: biayaPerawatan.replace(/,/g, ""),
          jenisBeli,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/biayaPerawatan/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Box>
      <Typography color="#757575">Perawatan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Biaya Perawatan
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />
      <Form noValidate validated={validated} onSubmit={updateBiayaPerawatan}>
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA MOTOR
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Mesin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noMesin} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Warna :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun Perakitan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tahun} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        {/* Biaya Perawatan (Input) */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            RINCIAN BIAYA PERAWATAN
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Bukti <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noBukti} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tanggal Perawatan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        minDate={minDate}
                        maxDate={new Date(user.periode.periodeAkhir)}
                        selected={inputTglPerawatan}
                        onChange={(e) => setInputTglPerawatan(e)}
                        customInput={
                          <TextField
                            error={error && inputTglPerawatan === null && true}
                            helperText={
                              error &&
                              inputTglPerawatan === null &&
                              "Tanggal Perawatan harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Biaya Perawatan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={biayaPerawatan}
                        required
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setBiayaPerawatan(tempNum);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Biaya Perawatan harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. COA <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={kodeCOA}
                        required
                        placeholder="Pilih..."
                        onClick={() => handleClickOpenCOA()}
                      />
                      <Form.Control.Feedback type="invalid">
                        No. COA harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keterangan}
                        onChange={(e) =>
                          setKeterangan(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/biayaPerawatan/${id}`)}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<EditIcon />} type="submit">
            Ubah
          </Button>
        </Box>
      </Form>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UbahBiayaPerawatan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const colorRed = {
  color: "red",
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};
