import * as React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Colors } from "../constants/styles";
import { formatDate } from "../constants/helper";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  tableLeftBorder: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  tableBottomRightBorder: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

export function ShowTableTipe({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.root}>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Isi (cc)
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Merk
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/tipe/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeTipe}
              </TableCell>
              <TableCell>{user.namaTipe}</TableCell>
              <TableCell>{user.noRangka}</TableCell>
              <TableCell>{user.noMesin}</TableCell>
              <TableCell>{user.isi}</TableCell>
              <TableCell>{user.merk}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableWarna({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.root}>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Warna</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/warna/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaWarna}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCOA({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sub Group COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Group COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis Saldo
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kas/Bank</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/COA/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeCOA}
              </TableCell>
              <TableCell>{user.namaCOA}</TableCell>
              <TableCell>{user.subGroupCOA}</TableCell>
              <TableCell>{user.groupCOA}</TableCell>
              <TableCell>{user.jenisCOA}</TableCell>
              <TableCell>{user.jenisSaldo}</TableCell>
              <TableCell>{user.kasBank}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJenisCOA({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Jenis COA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jenisCOA/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeJenisCOA}
              </TableCell>
              <TableCell>{user.namaJenisCOA}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableGroupCOA({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Jenis
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Jenis
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Group
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Group
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/groupCOA/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeJenisCOA}
              </TableCell>
              <TableCell>{user.namaJenisCOA}</TableCell>
              <TableCell>{user.kodeGroupCOA}</TableCell>
              <TableCell>{user.namaGroupCOA}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSubGroupCOA({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Group
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Sub Group
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Sub Group</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/subGroupCOA/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {`${user.kodeGroupCOA} - ${user.namaGroupCOA}`}
              </TableCell>
              <TableCell>{user.kodeSubGroupCOA}</TableCell>
              <TableCell>{user.namaSubGroupCOA}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableWilayah({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Wilayah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/wilayah/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeWilayah}
              </TableCell>
              <TableCell>{user.namaWilayah}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKecamatan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kecamatan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Kecamatan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Wilayah
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Wilayah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kecamatan/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeKecamatan}
              </TableCell>
              <TableCell>{user.namaKecamatan}</TableCell>
              <TableCell>{user.kodeWilayah}</TableCell>
              <TableCell>{user.namaWilayah}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDealer({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Dealer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>PIC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/dealer/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeDealer}
              </TableCell>
              <TableCell>{user.namaDealer}</TableCell>
              <TableCell>{user.alamatDealer}</TableCell>
              <TableCell>{user.teleponDealer}</TableCell>
              <TableCell>{user.PICDealer}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableRegister({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Register
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Ktp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/register/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noRegister}</TableCell>
              <TableCell>{user.tanggalRegister}</TableCell>
              <TableCell>{user.namaRegister}</TableCell>
              <TableCell>{user.almRegister}</TableCell>
              <TableCell>{user.tlpRegister}</TableCell>
              <TableCell>{user.noKtpRegister}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarReject({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Register
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KTP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Marketing
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Surveyor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarReject/reject/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noRegister}</TableCell>
              <TableCell>{user.tglRejectFormatted}</TableCell>
              <TableCell>{user.namaReject}</TableCell>
              <TableCell>{user.alamatReject}</TableCell>
              <TableCell>{user.tlpReject}</TableCell>
              <TableCell>{user.noKKReject}</TableCell>
              <TableCell>{user.noKTPReject}</TableCell>
              <TableCell>{user.nopolReject}</TableCell>
              <TableCell>
                {user.kodeMarketing.kodeMarketing} -{" "}
                {user.kodeMarketing.namaMarketing}
              </TableCell>
              <TableCell>
                {user.kodeSurveyor.kodeSurveyor} -{" "}
                {user.kodeSurveyor.namaSurveyor}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableRejectChild({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  let no = 0;

  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KTP
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Telepon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            no++;

            return (
              <TableRow
                key={user.kodeStok}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarReject/reject/${id}/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noKKRejectAnak}</TableCell>
                <TableCell>{user.noKTPRejectAnak}</TableCell>
                <TableCell>{user.namaRejectAnak}</TableCell>
                <TableCell>{user.tlpRejectAnak}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKolektor({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Eksekutor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kolektor/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeKolektor}
              </TableCell>
              <TableCell>{user.namaKolektor}</TableCell>
              <TableCell>{user.teleponKolektor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableMarketing({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Marketing
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Telepon Marketing</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/marketing/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeMarketing}
              </TableCell>
              <TableCell>{user.namaMarketing}</TableCell>
              <TableCell>{user.teleponMarketing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePekerjaan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Pekerjaan
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/pekerjaan/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodePekerjaan}
              </TableCell>
              <TableCell>{user.namaPekerjaan}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSurveyor({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Surveyor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon Surveyor
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jenis Surveyor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/surveyor/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeSurveyor}
              </TableCell>
              <TableCell>{user.namaSurveyor}</TableCell>
              <TableCell>{user.teleponSurveyor}</TableCell>
              <TableCell>{user.jenisSurveyor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableUser({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Username
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe User
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Periode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No Terakhir
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Cabang</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.username.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.tipeUser.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.periode.namaPeriode
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.kodeKwitansi
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.noTerakhir
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.cabang._id
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.cabang.namaCabang
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarUser/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.username}
                </TableCell>
                <TableCell>{user.tipeUser}</TableCell>
                <TableCell>{user.periode.namaPeriode}</TableCell>
                <TableCell>{user.kodeKwitansi}</TableCell>
                <TableCell>{user.noTerakhir}</TableCell>
                <TableCell>
                  {user.cabang._id} - {user.cabang.namaCabang}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCabang({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Cabang
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              PIC
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>COA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/cabang/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user._id}
              </TableCell>
              <TableCell>{user.namaCabang}</TableCell>
              <TableCell>{user.alamatCabang}</TableCell>
              <TableCell>{user.teleponCabang}</TableCell>
              <TableCell>{user.picCabang}</TableCell>
              <TableCell>
                {user.COA.kodeCOA} - {user.COA.namaCOA}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableUnitBisnis({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Unit Bisnis
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val._id.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.namaUnitBisnis
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/unitBisnis/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user._id}
                </TableCell>
                <TableCell>{user.namaUnitBisnis}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableLeasing({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Leasing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Leasing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat Leasing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon Leasing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              PIC
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>COA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/leasing/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeLeasing}
              </TableCell>
              <TableCell>{user.namaLeasing}</TableCell>
              <TableCell>{user.alamatLeasing}</TableCell>
              <TableCell>{user.teleponLeasing}</TableCell>
              <TableCell>{user.picLeasing}</TableCell>
              <TableCell>{user.coaLeasing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSupplier({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Supplier
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kota
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              PIC
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>NPWP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/supplier/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeSupplier}
              </TableCell>
              <TableCell>{user.namaSupplier}</TableCell>
              <TableCell>{user.alamatSupplier}</TableCell>
              <TableCell>{user.kotaSupplier}</TableCell>
              <TableCell>{user.teleponSupplier}</TableCell>
              <TableCell>{user.picSupplier}</TableCell>
              <TableCell>{user.npwpSupplier}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBeli({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Beli
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Supplier
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              PPN
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Potongan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Lama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jatuh Tempo
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nopol</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarBeli/beli/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noBeli}</TableCell>
              <TableCell>
                {user.supplier.kodeSupplier} - {user.supplier.namaSupplier}
              </TableCell>
              <TableCell align="right">
                {user.jumlahBeli.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.ppnBeli.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.potongan.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.lama.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.tanggalBeliFormatted}</TableCell>
              <TableCell>{user.jatuhTempoFormatted}</TableCell>
              <TableCell>{user.jenisBeli}</TableCell>
              <TableCell>{user.nopol}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarAngsuran({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              HR
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            function dhm(t) {
              var cd = 24 * 60 * 60 * 1000,
                ch = 60 * 60 * 1000,
                d = Math.floor(t / cd),
                h = Math.floor((t - d * cd) / ch),
                m = Math.round((t - d * cd - h * ch) / 60000),
                pad = function (n) {
                  return n < 10 ? "0" + n : n;
                };
              if (m === 60) {
                h++;
                m = 0;
              }
              if (h === 24) {
                d++;
                h = 0;
              }
              return d;
            }

            var d1 = new Date(); //"now"
            d1.setHours(0, 0, 0, 0);
            var d2 = new Date(user.tglJatuhTempo); // some date
            d2.setHours(0, 0, 0, 0);
            var diff = Math.abs(d1 - d2); // difference in milliseconds
            var total = dhm(diff);

            if (d1 < d2) {
              total = -total;
            }

            let tempTableColor;
            if (user.isTarik === true) {
              tempTableColor = Colors.yellow400;
              total = 0;
            } else if (user.sisaBulan === 0) {
              tempTableColor = Colors.red400;
              total = 0;
            } else if (user.isTitip === true) {
              tempTableColor = Colors.blue300;
            } else if (user.isSweeping === true) {
              tempTableColor = Colors.purple300;
            } else if (user.isMotorHilang === true) {
              tempTableColor = Colors.orange300;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarAngsuran/angsuran/${user.noJual}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.namaRegister}</TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.tanggalJualFormatted}</TableCell>
                <TableCell>{user.nopol}</TableCell>
                <TableCell>{user.almRegister}</TableCell>
                <TableCell>{user.tipe}</TableCell>
                <TableCell>{user.noRangka}</TableCell>
                <TableCell>{user.noMesin}</TableCell>
                <TableCell>{user.spKe}</TableCell>
                <TableCell>{total.toLocaleString("en-US")}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarAngsuranBedaCabang({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              HR
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Cabang</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            function dhm(t) {
              var cd = 24 * 60 * 60 * 1000,
                ch = 60 * 60 * 1000,
                d = Math.floor(t / cd),
                h = Math.floor((t - d * cd) / ch),
                m = Math.round((t - d * cd - h * ch) / 60000),
                pad = function (n) {
                  return n < 10 ? "0" + n : n;
                };
              if (m === 60) {
                h++;
                m = 0;
              }
              if (h === 24) {
                d++;
                h = 0;
              }
              return d;
            }

            var d1 = new Date(); //"now"
            d1.setHours(0, 0, 0, 0);
            var d2 = new Date(user.tglJatuhTempo); // some date
            d2.setHours(0, 0, 0, 0);
            var diff = Math.abs(d1 - d2); // difference in milliseconds
            var total = dhm(diff);

            if (d1 < d2) {
              total = -total;
            }

            let tempTableColor;
            if (user.isTarik === true) {
              tempTableColor = Colors.yellow400;
              total = 0;
            } else if (user.sisaBulan === 0) {
              tempTableColor = Colors.red400;
              total = 0;
            } else if (user.isTitip === true) {
              tempTableColor = Colors.blue300;
            } else if (user.isSweeping === true) {
              tempTableColor = Colors.purple300;
            } else if (user.isMotorHilang === true) {
              tempTableColor = Colors.orange300;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/daftarAngsuranBedaCabang/angsuranBedaCabang/${user.noJual}/${user.cabang._id}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {user.namaRegister}
                </TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.tanggalJualFormatted}</TableCell>
                <TableCell>{user.nopol}</TableCell>
                <TableCell>{user.almRegister}</TableCell>
                <TableCell>{user.tipe}</TableCell>
                <TableCell>{user.spKe}</TableCell>
                <TableCell>{total.toLocaleString("en-US")}</TableCell>
                <TableCell>
                  {user.cabang._id} - {user.cabang.namaCabang}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableBeli({ id, currentPosts, nomorNota }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tahun
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Warna
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama STNK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat STNK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. STNK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Harga Satuan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              PPN
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal Jual
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>No. Jual</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((aBeli, index) => (
            <TableRow
              key={aBeli.kodeStok}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarBeli/beli/${id}/${aBeli._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {aBeli.tipe}
              </TableCell>
              <TableCell>{aBeli.tahun}</TableCell>
              <TableCell>{aBeli.namaWarna}</TableCell>
              <TableCell>{aBeli.noRangka}</TableCell>
              <TableCell>{aBeli.noMesin}</TableCell>
              <TableCell>{aBeli.nopol}</TableCell>
              <TableCell>{aBeli.namaStnk}</TableCell>
              <TableCell>{aBeli.alamatStnk}</TableCell>
              <TableCell>{aBeli.tglStnkFormatted}</TableCell>
              <TableCell>{aBeli.jenisBeli}</TableCell>
              <TableCell align="right">
                {aBeli.hargaSatuan.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {aBeli.ppnABeli ? aBeli.ppnABeli.toLocaleString("en-US") : "0"}
              </TableCell>
              <TableCell>{aBeli.tanggalJualFormatted}</TableCell>
              <TableCell>{aBeli.noJual}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableAngsuran({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bayar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Modal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Bunga
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A/bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Eksekutor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Biaya Tarik
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Denda
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Potongan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jemputan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => {
            let tempTglBayar = formatDate(angsuran.tglBayar);
            let tempTglJatuhTempo = formatDate(angsuran.tglJatuhTempo);
            let tempMd1 = formatDate(angsuran.md1);
            let tempMd2 = formatDate(angsuran.md2);
            let tempMd3 = formatDate(angsuran.md3);
            let tempTableColor;
            if (angsuran.isPercepatan && angsuran.isBayarBedaCabang) {
              tempTableColor = Colors.green400;
            } else if (angsuran.isPercepatan) {
              tempTableColor = Colors.blue100;
            } else if (angsuran.isBayarBedaCabang) {
              tempTableColor = Colors.pink300;
            } else if (angsuran.isPenarikan) {
              tempTableColor = Colors.yellow400;
            }
            return (
              <TableRow
                key={angsuran._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarAngsuran/angsuran/${id}/${angsuran._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {tempTglBayar}
                </TableCell>
                <TableCell>{tempTglJatuhTempo}</TableCell>
                <TableCell>{angsuran.noKwitansi}</TableCell>
                <TableCell>{angsuran._id}</TableCell>
                <TableCell align="right">
                  {angsuran.angModal.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angBunga.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angPerBulan.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{tempMd1}</TableCell>
                <TableCell>
                  {angsuran.suratPenarikan
                    ? `${angsuran.suratPenarikan.kodeKolektor.kodeKolektor} - ${angsuran.suratPenarikan.kodeKolektor.namaKolektor}`
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {angsuran.biayaTarik.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.denda.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.potongan.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.jemputan.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableAngsuranBedaCabang({
  id,
  currentPosts,
  cabangAngsuran,
}) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bayar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Modal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Bunga
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A/bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md3
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>ST</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => {
            let tempTglBayar = formatDate(angsuran.tglBayar);
            let tempTglJatuhTempo = formatDate(angsuran.tglJatuhTempo);
            let tempMd1 = formatDate(angsuran.md1);
            let tempMd2 = formatDate(angsuran.md2);
            let tempMd3 = formatDate(angsuran.md3);
            let tempTableColor;
            if (angsuran.isPercepatan && angsuran.isBayarBedaCabang) {
              tempTableColor = Colors.green400;
            } else if (angsuran.isPercepatan) {
              tempTableColor = Colors.blue100;
            } else if (angsuran.isBayarBedaCabang) {
              tempTableColor = Colors.yellow100;
            } else if (angsuran.isPenarikan) {
              tempTableColor = Colors.red400;
            }
            return (
              <TableRow
                key={angsuran._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/daftarAngsuranBedaCabang/angsuranBedaCabang/${id}/${cabangAngsuran}/${angsuran._id}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {tempTglBayar}
                </TableCell>
                <TableCell>{tempTglJatuhTempo}</TableCell>
                <TableCell>{angsuran.noKwitansi}</TableCell>
                <TableCell>{angsuran._id}</TableCell>
                <TableCell align="right">
                  {angsuran.angModal.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angBunga.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angPerBulan.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{tempMd1}</TableCell>
                <TableCell>{tempMd2}</TableCell>
                <TableCell>{tempMd3}</TableCell>
                <TableCell>{angsuran.biayaTarik > 0 ? "✓" : "X"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarStok({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Beli
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal Beli
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Supplier
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Merk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Stnk</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jenis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
                backgroundColor:
                  user.tanggalJual !== null ? "#f79892" : "white",
              }}
              onClick={() => {
                navigate(`/daftarStok/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no}
              </TableCell>
              <TableCell>{user.noBeli}</TableCell>
              <TableCell>{formatDate(user.tanggalBeli)}</TableCell>
              <TableCell>
                {user.supplier ? user.supplier.kodeSupplier : ""} -{" "}
                {user.supplier ? user.supplier.namaSupplier : ""}
              </TableCell>
              <TableCell>{user.merk}</TableCell>
              <TableCell>
                {user.tipe} - {user.namaTipe}
              </TableCell>
              <TableCell>{user.noRangka}</TableCell>
              <TableCell>{user.noMesin}</TableCell>
              <TableCell>{user.nopol}</TableCell>
              <TableCell>{user.namaStnk}</TableCell>
              <TableCell>{user.jenisBeli}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableMasterJual({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kontrak
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Plat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tahun
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. BPKB
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Warna
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Telp
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Surveyor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Marketing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KTP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KK
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/masterCustomer/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noJual}</TableCell>
              <TableCell>{user.namaRegister}</TableCell>
              <TableCell>{user.almRegister}</TableCell>
              <TableCell>{user.tanggalJualFormatted}</TableCell>
              <TableCell>{user.nopol}</TableCell>
              <TableCell>{user.tahun}</TableCell>
              <TableCell>{user.noRangka}</TableCell>
              <TableCell>
                {user.tipe} - {user.namaTipe}
              </TableCell>
              <TableCell>{user.noMesin}</TableCell>
              <TableCell>{user.noBpkb}</TableCell>
              <TableCell>{user.namaWarna}</TableCell>
              <TableCell>{user.tlpRegister}</TableCell>
              <TableCell>{`${user.kodeSurveyor.kodeSurveyor} - ${user.kodeSurveyor.namaSurveyor}`}</TableCell>
              <TableCell>{`${user.kodeMarketing.kodeMarketing} - ${user.kodeMarketing.namaMarketing}`}</TableCell>
              <TableCell>{user.noKtpRegister}</TableCell>
              <TableCell>{user.noKKRegister}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJualCustomerPerSurveyor({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kontrak
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Plat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tahun
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. BPKB
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Warna
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Telp
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Surveyor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Marketing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KTP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KK
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/customerPerSurveyor/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noJual}</TableCell>
              <TableCell>{user.namaRegister}</TableCell>
              <TableCell>{user.almRegister}</TableCell>
              <TableCell>{user.tanggalJualFormatted}</TableCell>
              <TableCell>{user.nopol}</TableCell>
              <TableCell>{user.tahun}</TableCell>
              <TableCell>{user.noRangka}</TableCell>
              <TableCell>
                {user.tipe} - {user.namaTipe}
              </TableCell>
              <TableCell>{user.noMesin}</TableCell>
              <TableCell>{user.noBpkb}</TableCell>
              <TableCell>{user.namaWarna}</TableCell>
              <TableCell>{user.tlpRegister}</TableCell>
              <TableCell>{`${user.kodeSurveyor.kodeSurveyor} - ${user.kodeSurveyor.namaSurveyor}`}</TableCell>
              <TableCell>{`${user.kodeMarketing.kodeMarketing} - ${user.kodeMarketing.namaMarketing}`}</TableCell>
              <TableCell>{user.noKtpRegister}</TableCell>
              <TableCell>{user.noKKRegister}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJual({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Leasing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Tipe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jual/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noJual}</TableCell>
              <TableCell>{user.tanggalJualFormatted}</TableCell>
              <TableCell>{user.namaRegister}</TableCell>
              <TableCell>
                {user.kodeLeasing.kodeLeasing} - {user.kodeLeasing.namaLeasing}
              </TableCell>
              <TableCell>{user.nopol}</TableCell>
              <TableCell>
                {user.tipe} - {user.namaTipe}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJualCustomer({ currentPosts, id }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Leasing
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Tipe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/customer/${id}/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noJual}
              </TableCell>
              <TableCell>{user.tanggalJualFormatted}</TableCell>
              <TableCell>{user.namaRegister}</TableCell>
              <TableCell>
                {user.kodeLeasing.kodeLeasing} - {user.kodeLeasing.namaLeasing}
              </TableCell>
              <TableCell>{user.tipe}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCustomer({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Register
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Ktp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/customer/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.no.toLocaleString("en-US")}
              </TableCell>
              <TableCell>{user.noRegister}</TableCell>
              <TableCell>{user.namaRegister}</TableCell>
              <TableCell>{user.almRegister}</TableCell>
              <TableCell>{user.tlpRegister}</TableCell>
              <TableCell>{user.noKtpRegister}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTakeover({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/takeover/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.noTakeover}
                </TableCell>
                <TableCell>{formatDate(user.tanggalTakeover)}</TableCell>
                <TableCell>{user.noJual}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSuratPemberitahuan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Angsuran
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. SP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP Ke-
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Angsuran
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Bln
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Eksekutor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.noJual.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.idJual.namaRegister
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.idJual.almRegister
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.idJual.tglAng
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.tglSp.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.idJual.nopol
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => {
              let tempTglAng = formatDate(user.idJual.tglAng);
              let tempTglSp = formatDate(user.tglSp);
              return (
                <TableRow
                  key={user._id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { bgcolor: Colors.grey300 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/suratPemberitahuan/${user._id}`);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {user.noJual}
                  </TableCell>
                  <TableCell>{user.idJual.namaRegister}</TableCell>
                  <TableCell>{user.idJual.almRegister}</TableCell>
                  <TableCell>{tempTglAng}</TableCell>
                  <TableCell>{tempTglSp}</TableCell>
                  <TableCell align="right">{user.spKe}</TableCell>
                  <TableCell align="right">
                    {user.idJual.angPerBulan.toLocaleString("en-US")}
                  </TableCell>
                  <TableCell align="right">
                    {user.idJual.tenor - user.idJual.sisaBulan}
                  </TableCell>
                  <TableCell>{user.idJual.nopol}</TableCell>
                  <TableCell>{`${
                    user.kodeKolektor && user.kodeKolektor.kodeKolektor
                  } - ${
                    user.kodeKolektor && user.kodeKolektor.namaKolektor
                  }`}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSuratPenarikan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. ST
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. ST
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Eksekutor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Konsumen
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTglSt = formatDate(user.tglSt);
            let tempTableColor;
            if (user.isBayar == false) {
              tempTableColor = Colors.red400;
            } else if (user.isTitip == true) {
              tempTableColor = Colors.blue300;
            } else if (user.isTarik == true) {
              tempTableColor = Colors.yellow400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/suratPenarikan/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.noSt}</TableCell>
                <TableCell>{tempTglSt}</TableCell>
                <TableCell>{`${
                  user.kodeKolektor && user.kodeKolektor.kodeKolektor
                } - ${
                  user.kodeKolektor && user.kodeKolektor.namaKolektor
                }`}</TableCell>
                <TableCell>{user.idJual.namaRegister}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePemutihan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. PT
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Pengajuan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Total Sisa Piutang
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTglPengajuan = formatDate(user.tglPengajuan);
            let tempTableColor;
            if (user.status == "APPROVE" && user.status2 == "APPROVE") {
              tempTableColor = Colors.green400;
            } else {
              tempTableColor = Colors.red400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarPemutihan/pemutihan/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{tempTglPengajuan}</TableCell>
                <TableCell align="right">
                  {user.jumlahChild.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {user.totalSisaPiutang.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePemutihanSemuaCabang({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. PT
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Pengajuan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Total Sisa Piutang
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Cabang
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTglPengajuan = formatDate(user.tglPengajuan);
            let tempTableColor;
            if (user.status == "APPROVE" && user.status2 == "APPROVE") {
              tempTableColor = Colors.green400;
            } else {
              tempTableColor = Colors.red400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/daftarApprovePemutihan/approvepemutihan/${user._id}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{tempTglPengajuan}</TableCell>
                <TableCell align="right">
                  {user.jumlahChild.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {user.totalSisaPiutang.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{`${user.cabang._id}-${user.cabang.namaCabang}`}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePemutihanChild({
  currentPosts,
  searchTerm,
  idPemutihan,
}) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. PT
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Pengajuan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Hari Telat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sisa Piutang
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTglPengajuan = formatDate(user.tglPengajuan);
            let tempTableColor;
            if (user.status == "APPROVE" && user.status2 == "APPROVE") {
              tempTableColor = Colors.green400;
            } else {
              tempTableColor = Colors.red400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/daftarPemutihan/pemutihan/${idPemutihan}/${user._id}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{tempTglPengajuan}</TableCell>
                <TableCell align="right">
                  {user.hariTelat.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.namaRegister}</TableCell>
                <TableCell align="right">
                  {(
                    user.idJual.sisaBulan * user.idJual.angModal
                  ).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableApprovePemutihanChild({
  currentPosts,
  searchTerm,
  idPemutihan,
}) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. PT
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Pengajuan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Hari Telat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Customer
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sisa Piutang
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTglPengajuan = formatDate(user.tglPengajuan);
            let tempTableColor;
            if (user.status == "APPROVE" && user.status2 == "APPROVE") {
              tempTableColor = Colors.green400;
            } else {
              tempTableColor = Colors.red400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/daftarApprovePemutihan/approvePemutihan/${idPemutihan}/${user._id}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{tempTglPengajuan}</TableCell>
                <TableCell align="right">
                  {user.hariTelat.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.namaRegister}</TableCell>
                <TableCell align="right">
                  {(
                    user.idJual.sisaBulan * user.idJual.angModal
                  ).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePointKembali({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. PK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Ketemu
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/pointKembali/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.noPointKembali}</TableCell>
                <TableCell>{formatDate(user.tglKetemu)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePenarikan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sisa Bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tenor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Marketing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Surveyor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ket
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTglPenarikan = formatDate(user.tglPenarikan);
            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/penarikan/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.noPenarikan}</TableCell>
                <TableCell>{tempTglPenarikan}</TableCell>
                <TableCell align="right">
                  {user.sisaBulanPenarikan.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {(user.idJual.tenor - user.sisaBulanPenarikan).toLocaleString(
                    "en-US"
                  )}
                </TableCell>
                <TableCell>{user.namaMarketing}</TableCell>
                <TableCell>{user.namaSurveyor}</TableCell>
                <TableCell>{user.ketPenarikan}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTitip({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Titip
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Penarikan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Marketing
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Surveyor
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Eksekutor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTableColor;
            if (user.isTarik == true) {
              tempTableColor = Colors.yellow400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/titip/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noTitip}</TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.namaRegister}</TableCell>
                <TableCell>{user.nopol}</TableCell>
                <TableCell>{user.tglPenarikanFormatted}</TableCell>
                <TableCell>{user.namaMarketing}</TableCell>
                <TableCell>{user.namaSurveyor}</TableCell>
                <TableCell>{user.kodeKolektor.namaKolektor}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSweeping({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sweeping
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Sweeping
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTableColor;
            if (user.isTarik == true) {
              tempTableColor = Colors.yellow400;
            } else if (user.idJual.isSweeping == false) {
              tempTableColor = Colors.red400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/sweeping/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noSweeping}</TableCell>
                <TableCell>{user.tglSweepingFormatted}</TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.namaRegister}</TableCell>
                <TableCell>{user.nopol}</TableCell>
                <TableCell>{user.noRangka}</TableCell>
                <TableCell>{user.noMesin}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableMotorHilang({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Urut
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let tempTableColor;
            if (user.isTarik == true) {
              tempTableColor = Colors.yellow400;
            } else if (user.idJual.isSweeping == false) {
              tempTableColor = Colors.red400;
            }

            return (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/motorHilang/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{user.noMotorHilang}</TableCell>
                <TableCell>{user.tglMotorHilangFormatted}</TableCell>
                <TableCell>{user.noJual}</TableCell>
                <TableCell>{user.namaRegister}</TableCell>
                <TableCell>{user.nopol}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableBiayaPerawatan({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nopol
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Biaya</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.noBukti.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.nopol.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.tglPerawatanFormatted
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.keterangan
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.COA.kodeCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.COA.namaCOA.toString().includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/biayaPerawatan/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.noBukti}
                </TableCell>
                <TableCell>{user.nopol}</TableCell>
                <TableCell>{user.tglPerawatanFormatted}</TableCell>
                <TableCell>
                  {user.COA.kodeCOA} - {user.COA.namaCOA}
                </TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.biayaPerawatan.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarKasMasuk({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.no === parseInt(searchTerm) ||
                val.noBukti.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.tglKasMasukFormatted.toString().includes(searchTerm) ||
                val.COA.kodeCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.COA.namaCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.keterangan
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarKasMasuk/kasMasuk/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no}
                </TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{user.tglKasMasukFormatted}</TableCell>
                <TableCell>{`${user.COA.kodeCOA} - ${user.COA.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKasMasuk({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((kasMasukChild, index) => (
            <TableRow
              key={kasMasukChild.kodeStok}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarKasMasuk/kasMasuk/${id}/${kasMasukChild._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {kasMasukChild.noBukti}
              </TableCell>
              <TableCell>{kasMasukChild.tglKasMasukFormatted}</TableCell>
              <TableCell>{`${kasMasukChild.COA.kodeCOA} - ${kasMasukChild.COA.namaCOA}`}</TableCell>
              <TableCell>{kasMasukChild.keterangan}</TableCell>
              <TableCell align="right">
                {kasMasukChild.jumlah.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarKasKeluar({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.no === parseInt(searchTerm) ||
                val.noBukti.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.tglKasKeluarFormatted.toString().includes(searchTerm) ||
                val.COA.kodeCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.COA.namaCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.keterangan
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarKasKeluar/kasKeluar/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no}
                </TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{user.tglKasKeluarFormatted}</TableCell>
                <TableCell>{`${user.COA.kodeCOA} - ${user.COA.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKasKeluar({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((kasKeluarChild, index) => (
            <TableRow
              key={kasKeluarChild.kodeStok}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarKasKeluar/kasKeluar/${id}/${kasKeluarChild._id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {kasKeluarChild.noBukti}
              </TableCell>
              <TableCell>{kasKeluarChild.tglKasKeluarFormatted}</TableCell>
              <TableCell>{`${kasKeluarChild.COA.kodeCOA} - ${kasKeluarChild.COA.namaCOA}`}</TableCell>
              <TableCell>{kasKeluarChild.keterangan}</TableCell>
              <TableCell align="right">
                {kasKeluarChild.jumlah.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBankMasuk({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.no === parseInt(searchTerm) ||
                val.noBukti.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.tglBankMasukFormatted.toString().includes(searchTerm) ||
                val.COA.kodeCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.COA.namaCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.keterangan
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarBankMasuk/bankMasuk/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no}
                </TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{user.tglBankMasukFormatted}</TableCell>
                <TableCell>{`${user.COA.kodeCOA} - ${user.COA.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableBankMasuk({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((bankMasukChild, index) => (
            <TableRow
              key={bankMasukChild.kodeStok}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarBankMasuk/bankMasuk/${id}/${bankMasukChild._id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {bankMasukChild.noBukti}
              </TableCell>
              <TableCell>{bankMasukChild.tglBankMasukFormatted}</TableCell>
              <TableCell>{`${bankMasukChild.COA.kodeCOA} - ${bankMasukChild.COA.namaCOA}`}</TableCell>
              <TableCell>{bankMasukChild.keterangan}</TableCell>
              <TableCell align="right">
                {bankMasukChild.jumlah.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBankKeluar({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.no === parseInt(searchTerm) ||
                val.noBukti.toUpperCase().includes(searchTerm.toUpperCase()) ||
                val.tglBankKeluarFormatted.toString().includes(searchTerm) ||
                val.COA.kodeCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.COA.namaCOA.toString().includes(searchTerm.toUpperCase()) ||
                val.keterangan
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarBankKeluar/bankKeluar/${user._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.no}
                </TableCell>
                <TableCell>{user.noBukti}</TableCell>
                <TableCell>{user.tglBankKeluarFormatted}</TableCell>
                <TableCell>{`${user.COA.kodeCOA} - ${user.COA.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableBankKeluar({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((bankKeluarChild, index) => (
            <TableRow
              key={bankKeluarChild.kodeStok}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarBankKeluar/bankKeluar/${id}/${bankKeluarChild._id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {bankKeluarChild.noBukti}
              </TableCell>
              <TableCell>{bankKeluarChild.tglBankKeluarFormatted}</TableCell>
              <TableCell>{`${bankKeluarChild.COA.kodeCOA} - ${bankKeluarChild.COA.namaCOA}`}</TableCell>
              <TableCell>{bankKeluarChild.keterangan}</TableCell>
              <TableCell align="right">
                {bankKeluarChild.jumlah.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableNeracaSaldo({
  currentPosts,
  totalDebet,
  totalKredit,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Rekening
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Saldo Awal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Debet
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kredit
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Saldo Akhir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeCOA}
              </TableCell>
              <TableCell>{user.namaCOA}</TableCell>
              <TableCell align="right">
                {parseInt(user.saldoAwal).toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {parseInt(user.debet).toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {parseInt(user.kredit).toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {parseInt(user.saldoAkhir).toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            key={"count"}
            sx={{
              bgcolor: Colors.grey300,
            }}
          >
            <TableCell component="th" scope="row"></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 700 }}>Balance :</Typography>
            </TableCell>
            <TableCell align="right">
              {totalDebet.toLocaleString("en-US")}
            </TableCell>
            <TableCell align="right">
              {totalKredit.toLocaleString("en-US")}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow
            key={"count"}
            sx={{
              bgcolor: Colors.grey300,
            }}
          >
            <TableCell component="th" scope="row"></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 700 }}>Selisih :</Typography>
            </TableCell>
            <TableCell align="right">
              {(totalDebet - totalKredit).toLocaleString("en-US")}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePeriode({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Awal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Akhir
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Periode</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/gantiPeriode/${user._id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.periodeAwalFormatted}
              </TableCell>
              <TableCell>{user.periodeAkhirFormatted}</TableCell>
              <TableCell>{user.namaPeriode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSemuaReject({ currentPosts, searchTerm }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. KK
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>No. KTP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaReject}
              </TableCell>
              <TableCell>{user.tlpReject}</TableCell>
              <TableCell>{user.noKKReject}</TableCell>
              <TableCell>{user.noKTPReject}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCustomerJualAngsuran({ id, idJual, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bayar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Modal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Bunga
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A/bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Md3
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>ST</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => {
            let tempTglBayar = formatDate(angsuran.tglBayar);
            let tempTglJatuhTempo = formatDate(angsuran.tglJatuhTempo);
            let tempMd1 = formatDate(angsuran.md1);
            let tempMd2 = formatDate(angsuran.md2);
            let tempMd3 = formatDate(angsuran.md3);
            let tempTableColor;
            if (angsuran.isPercepatan && angsuran.isBayarBedaCabang) {
              tempTableColor = Colors.green400;
            } else if (angsuran.isPercepatan) {
              tempTableColor = Colors.blue100;
            } else if (angsuran.isBayarBedaCabang) {
              tempTableColor = Colors.yellow100;
            } else if (angsuran.isPenarikan) {
              tempTableColor = Colors.red400;
            }
            return (
              <TableRow
                key={angsuran._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: tempTableColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/customer/${id}/${idJual}/${angsuran._id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {tempTglBayar}
                </TableCell>
                <TableCell>{tempTglJatuhTempo}</TableCell>
                <TableCell>{angsuran.noKwitansi}</TableCell>
                <TableCell>{angsuran._id}</TableCell>
                <TableCell align="right">
                  {angsuran.angModal.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angBunga.toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angPerBulan.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{tempMd1}</TableCell>
                <TableCell>{tempMd2}</TableCell>
                <TableCell>{tempMd3}</TableCell>
                <TableCell>{angsuran.biayaTarik > 0 ? "✓" : "X"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDashboardCabangNpl({
  tunggakans,
  angsurans,
  totalTotalNumberNpl,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              align="center"
              colSpan={4}
              sx={{ fontWeight: "bold" }}
              className={classes.tableBottomBorder}
            >
              Tunggakan
            </TableCell>
            <TableCell
              align="center"
              rowSpan={2}
              sx={{ fontWeight: "bold" }}
              className={classes.tableLeftBorder}
            >
              Total
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              1-30
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              31-60
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              61-90
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              over 90
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              bgcolor: Colors.red400,
            }}
          >
            <TableCell component="th" scope="row" colSpan={5}>
              <b>TUNGGAKAN</b>
            </TableCell>
          </TableRow>
          {tunggakans.map((user, index) => (
            <TableRow
              sx={{
                bgcolor: Colors.red400,
              }}
            >
              <TableCell component="th" scope="row" align="right">
                {user.totalSatuBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalDuaBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalTigaBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalLebihDariTigaBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalTotalNumber.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              bgcolor: Colors.blue300,
            }}
          >
            <TableCell component="th" scope="row" colSpan={5}>
              <b>SEMUA ANGSURAN</b>
            </TableCell>
          </TableRow>
          {angsurans.map((user, index) => (
            <TableRow
              sx={{
                bgcolor: Colors.blue300,
              }}
            >
              <TableCell component="th" scope="row" align="right">
                0
              </TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">
                {user.totalTotalNumber.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              bgcolor: Colors.purple300,
            }}
          >
            <TableCell component="th" scope="row" colSpan={5}>
              <b>PERSENTASE</b>
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              bgcolor: Colors.purple300,
            }}
          >
            <TableCell component="th" scope="row" align="right">
              0
            </TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">
              {totalTotalNumberNpl &&
                `${totalTotalNumberNpl.toLocaleString("en-US")} %`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDashboardCabangNplKuantitas({
  tunggakans,
  angsurans,
  totalTotalNumberNpl,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              align="center"
              colSpan={4}
              sx={{ fontWeight: "bold" }}
              className={classes.tableBottomBorder}
            >
              Tunggakan
            </TableCell>
            <TableCell
              align="center"
              rowSpan={2}
              sx={{ fontWeight: "bold" }}
              className={classes.tableLeftBorder}
            >
              Total
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              1-30
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              31-60
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              61-90
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              over 90
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              bgcolor: Colors.red400,
            }}
          >
            <TableCell component="th" scope="row" colSpan={5}>
              <b>TUNGGAKAN</b>
            </TableCell>
          </TableRow>
          {tunggakans.map((user, index) => (
            <TableRow
              sx={{
                bgcolor: Colors.red400,
              }}
            >
              <TableCell component="th" scope="row" align="right">
                {user.totalSatuBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalDuaBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalTigaBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalLebihDariTigaBulanNumber.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalTotalNumber.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              bgcolor: Colors.blue300,
            }}
          >
            <TableCell component="th" scope="row" colSpan={5}>
              <b>SEMUA ANGSURAN</b>
            </TableCell>
          </TableRow>
          {angsurans.map((user, index) => (
            <TableRow
              sx={{
                bgcolor: Colors.blue300,
              }}
            >
              <TableCell component="th" scope="row" align="right">
                0
              </TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">
                {user.totalTotalNumber.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              bgcolor: Colors.purple300,
            }}
          >
            <TableCell component="th" scope="row" colSpan={5}>
              <b>PERSENTASE</b>
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              bgcolor: Colors.purple300,
            }}
          >
            <TableCell component="th" scope="row" align="right">
              0
            </TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">
              {totalTotalNumberNpl &&
                `${totalTotalNumberNpl.toLocaleString("en-US")} %`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDashboardCabangNplDetail({ juals }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              align="center"
              rowSpan={2}
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Cabang
            </TableCell>
            <TableCell
              align="center"
              colSpan={2}
              sx={{ fontWeight: "bold" }}
              className={classes.tableBottomRightBorder}
            >
              Total Piutang
            </TableCell>
            <TableCell
              align="center"
              colSpan={2}
              sx={{ fontWeight: "bold" }}
              className={classes.tableBottomRightBorder}
            >
              J. Tempo
            </TableCell>
            <TableCell
              align="center"
              colSpan={2}
              sx={{ fontWeight: "bold" }}
              className={classes.tableBottomRightBorder}
            >
              NPL
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              Rupiah
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              Qty
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              Rupiah
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              Qty
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              Rupiah
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
              align="right"
            >
              Qty
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {juals.map((user, index) => (
            <TableRow>
              <TableCell component="th" scope="row">
                {user.namaCabang}
              </TableCell>
              <TableCell align="right">
                {user.totalJuals.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.kuantitasJuals.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.totalTunggakkan.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.kuantitasTunggakkan.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.npl.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {user.kuantitasNpl.toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
