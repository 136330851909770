import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { formatDate } from "../../../constants/helper";
import { Box, Typography, Button, Divider, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const KasHarian = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const { screenSize } = useStateContext();

  const downloadPdf = async () => {
    setLoading(true);
    let tempInputTgl = inputSampaiTgl;
    let sampaiTgl =
      inputSampaiTgl?.getFullYear() +
      "-" +
      (inputSampaiTgl?.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      inputSampaiTgl?.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

    let tempInputSampaiTgl = inputSampaiTgl;
    if (inputSampaiTgl.getDate() > 1) {
      tempInputSampaiTgl.setDate(tempInputSampaiTgl.getDate() - 1);
    }

    let sampaiTglMinOne =
      tempInputSampaiTgl?.getFullYear() +
      "-" +
      (tempInputSampaiTgl?.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      tempInputSampaiTgl?.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

    let tempPersenLoading = parseInt(persenLoading);

    let jurnalPostingsTotalKas = await axios.post(
      `${tempUrl}/neracaSaldosTotalKas`,
      {
        coaKas: setting.coaKas,
        sampaiTgl: sampaiTglMinOne,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let jurnalPostingsTotalBank = await axios.post(
      `${tempUrl}/neracaSaldosTotalBank`,
      {
        coaBank: setting.coaBank,
        sampaiTgl: sampaiTglMinOne,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
        namaCabang: user.cabang.namaCabang,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let jurnalPostingsPenerimaanKas = await axios.post(
      `${tempUrl}/penerimaanKas`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let jurnalPostingsPenerimaanKasSampaiSekarang = await axios.post(
      `${tempUrl}/penerimaanKasSampaiSekarang`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let tempPenerimaanKasSampaiSekarangAll = [];

    for (let j = 0; j < jurnalPostingsPenerimaanKasSampaiSekarang.length; j++) {
      tempPenerimaanKasSampaiSekarangAll.push(0);
    }

    let tempPenerimaanKasSampaiSekarang = 0;
    if (
      Object.keys(jurnalPostingsPenerimaanKasSampaiSekarang.data).length > 0 ||
      Object.keys(jurnalPostingsPenerimaanKasSampaiSekarang.data).length > 0
    ) {
      let keysPenerimaanKas = Object.keys(
        jurnalPostingsPenerimaanKasSampaiSekarang.data
      );
      for (let j = 0; j < keysPenerimaanKas.length; j++) {
        let sumJumlah = jurnalPostingsPenerimaanKasSampaiSekarang.data[
          keysPenerimaanKas[j]
        ]
          .map((o) => o.jumlah)
          .reduce((a, c) => {
            return a + c;
          });

        tempPenerimaanKasSampaiSekarangAll[j] = sumJumlah;
      }
    }

    let jurnalPostingsPenerimaanBank = await axios.post(
      `${tempUrl}/penerimaanBank`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
        namaCabang: user.cabang.namaCabang,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let jurnalPostingsPenerimaanBankSampaiSekarang = await axios.post(
      `${tempUrl}/penerimaanBankSampaiSekarang`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
        namaCabang: user.cabang.namaCabang,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let tempPenerimaanBankSampaiSekarangAll = [];

    for (
      let j = 0;
      j < jurnalPostingsPenerimaanBankSampaiSekarang.length;
      j++
    ) {
      tempPenerimaanBankSampaiSekarangAll.push(0);
    }

    let tempPenerimaanBankSampaiSekarang = 0;
    if (
      Object.keys(jurnalPostingsPenerimaanBankSampaiSekarang.data).length > 0 ||
      Object.keys(jurnalPostingsPenerimaanBankSampaiSekarang.data).length > 0
    ) {
      let keysPenerimaanBank = Object.keys(
        jurnalPostingsPenerimaanBankSampaiSekarang.data
      );
      for (let j = 0; j < keysPenerimaanBank.length; j++) {
        let sumJumlah = jurnalPostingsPenerimaanBankSampaiSekarang.data[
          keysPenerimaanBank[j]
        ]
          .map((o) => o.jumlah)
          .reduce((a, c) => {
            return a + c;
          });

        tempPenerimaanBankSampaiSekarangAll[j] = sumJumlah;
      }
    }

    let jurnalPostingsPengeluaranKas = await axios.post(
      `${tempUrl}/pengeluaranKas`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let jurnalPostingsPengeluaranKasSampaiSekarang = await axios.post(
      `${tempUrl}/pengeluaranKasSampaiSekarang`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let tempPengeluaranKasSampaiSekarangAll = [];

    for (
      let j = 0;
      j < jurnalPostingsPengeluaranKasSampaiSekarang.length;
      j++
    ) {
      tempPengeluaranKasSampaiSekarangAll.push(0);
    }

    let tempPengeluaranKasSampaiSekarang = 0;
    if (
      Object.keys(jurnalPostingsPengeluaranKasSampaiSekarang.data).length > 0 ||
      Object.keys(jurnalPostingsPengeluaranKasSampaiSekarang.data).length > 0
    ) {
      let keysPengeluaranKas = Object.keys(
        jurnalPostingsPengeluaranKasSampaiSekarang.data
      );
      for (let j = 0; j < keysPengeluaranKas.length; j++) {
        let sumJumlah = jurnalPostingsPengeluaranKasSampaiSekarang.data[
          keysPengeluaranKas[j]
        ]
          .map((o) => o.jumlah)
          .reduce((a, c) => {
            return a + c;
          });

        tempPengeluaranKasSampaiSekarangAll[j] = sumJumlah;
      }
    }

    let jurnalPostingsPengeluaranBank = await axios.post(
      `${tempUrl}/pengeluaranBank`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
        namaCabang: user.cabang.namaCabang,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let jurnalPostingsPengeluaranBankSampaiSekarang = await axios.post(
      `${tempUrl}/pengeluaranBankSampaiSekarang`,
      {
        sampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
        namaCabang: user.cabang.namaCabang,
      }
    );
    tempPersenLoading += 9;
    setPersenLoading(tempPersenLoading.toString());

    let tempPengeluaranBankSampaiSekarangAll = [];

    for (
      let j = 0;
      j < jurnalPostingsPengeluaranBankSampaiSekarang.length;
      j++
    ) {
      tempPengeluaranBankSampaiSekarangAll.push(0);
    }

    let tempPengeluaranBankSampaiSekarang = 0;
    if (
      Object.keys(jurnalPostingsPengeluaranBankSampaiSekarang.data).length >
        0 ||
      Object.keys(jurnalPostingsPengeluaranBankSampaiSekarang.data).length > 0
    ) {
      let keysPengeluaranBank = Object.keys(
        jurnalPostingsPengeluaranBankSampaiSekarang.data
      );
      for (let j = 0; j < keysPengeluaranBank.length; j++) {
        let sumJumlah = jurnalPostingsPengeluaranBankSampaiSekarang.data[
          keysPengeluaranBank[j]
        ]
          .map((o) => o.jumlah)
          .reduce((a, c) => {
            return a + c;
          });

        tempPengeluaranBankSampaiSekarangAll[j] = sumJumlah;
      }
    }

    let tempSisa = 0;
    let tempSaldoAwalAllKas = [];
    let tempSaldoAwalUangTunai = 0;
    let tempSaldoAwalAllBank = [];
    let tempSaldoAwalBank = 0;
    let tempTotalPenerimaan = 0;
    let tempPenerimaanAllKas = [];
    let tempPenerimaanUangTunai = 0;
    let tempPenerimaanAllBank = [];
    let tempPenerimaanBank = 0;
    let tempTotalPengeluaran = 0;
    let tempPengeluaranAllKas = [];
    let tempPengeluaranUangTunai = 0;
    let tempPengeluaranAllBank = [];
    let tempPengeluaranBank = 0;
    let hal = 1;
    let tempY = 5;
    let tempYStart;
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    const doc = new jsPDF("p", "mm", [240, 300]);
    doc.setFontSize(9);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      10,
      290
    );
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`,
      10,
      tempY
    );
    doc.text(`Hal: ${hal}`, 220, 5);
    tempY += 5;
    doc.text(`${setting.lokasiPerusahaan}`, 10, tempY);
    tempY += 5;
    doc.text(`${setting.lokasiSP}`, 10, tempY);
    tempY += 5;
    doc.text(`${setting.kotaPerusahaan}`, 10, tempY);
    tempYStart = tempY;
    tempY += 10;
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text(`LAPORAN KAS HARIAN`, 100, tempY);
    doc.setFont(undefined, "normal");
    doc.setFontSize(9);
    tempY += 5;
    doc.text(`Per Tanggal : ${formatDate(new Date(sampaiTgl))}`, 10, tempY);
    tempY += 5;
    doc.line(10, tempY, 230, tempY);
    doc.line(20, tempY, 20, tempY + 8);
    doc.line(50, tempY, 50, tempY + 8);
    doc.line(130, tempY, 130, tempY + 8);
    doc.line(165, tempY, 165, tempY + 8);
    doc.line(200, tempY, 200, tempY + 8);
    tempY += 5;
    doc.setFont(undefined, "bold");
    doc.text(`NO.`, 12, tempY);
    doc.text(`BUKTI`, 30, tempY);
    doc.text(`KETERANGAN`, 80, tempY);
    doc.text(`MASUK`, 143, tempY);
    doc.text(`KELUAR`, 175, tempY);
    doc.text(`SISA`, 210, tempY);
    doc.setFont(undefined, "normal");
    tempY += 3;
    doc.line(10, tempY, 230, tempY);
    tempY += 5;
    doc.setFont(undefined, "bold");
    doc.text(`Saldo Awal :`, 12, tempY);
    doc.setFont(undefined, "normal");

    let keysTotalKas = Object.keys(jurnalPostingsTotalKas.data);

    for (let j = 0; j < keysTotalKas.length; j++) {
      tempSaldoAwalAllKas.push(0);
    }

    for (let j = 0; j < keysTotalKas.length; j++) {
      tempY += 5;
      doc.setFont(undefined, "bold");
      doc.text(
        `Saldo Awal : ${
          jurnalPostingsTotalKas.data[keysTotalKas[j]][0].namaCOA
        }`,
        12,
        tempY
      );
      doc.setFont(undefined, "normal");
      tempY += 3;
      doc.line(10, tempY, 230, tempY);
      doc.line(20, tempY, 20, tempY + 6);
      doc.line(50, tempY, 50, tempY + 6);
      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      doc.line(200, tempY, 200, tempY + 6);
      tempY += 4;
      doc.text(`1`, 15, tempY);
      doc.text(`S.AWAL`, 22, tempY);
      doc.text(`Saldo Kas Per Tanggal ${sampaiTglMinOne}`, 53, tempY);

      let tempSisaSaldoKas =
        (jurnalPostingsTotalKas.data[keysTotalKas[j]][0].jumlah !== null
          ? jurnalPostingsTotalKas.data[keysTotalKas[j]][0].jumlah
          : 0) +
        (tempPenerimaanKasSampaiSekarangAll[j] !== null
          ? tempPenerimaanKasSampaiSekarangAll[j]
          : 0) -
        (tempPengeluaranKasSampaiSekarangAll[j] !== null
          ? tempPengeluaranKasSampaiSekarangAll[j]
          : 0);

      doc.text(`${tempSisaSaldoKas.toLocaleString("en-US")}`, 160, tempY, {
        align: "right",
      });

      tempSisa += tempSisaSaldoKas;
      tempSaldoAwalUangTunai += tempSisaSaldoKas;
      tempSaldoAwalAllKas[j] += tempSisaSaldoKas;
      doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
        align: "right",
      });
      tempY += 2;
      doc.line(10, tempY, 230, tempY);
      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      tempY += 4;
      doc.setFontSize(8);
      doc.setFont(undefined, "bold");
      doc.text(
        `Total Saldo Awal : ${
          jurnalPostingsTotalKas.data[keysTotalKas[j]][0].namaCOA
        }`,
        12,
        tempY
      );
      doc.setFontSize(9);
      doc.text(`${tempSisaSaldoKas.toLocaleString("en-US")}`, 160, tempY, {
        align: "right",
      });
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(130, tempY, 165, tempY);
    }

    doc.line(130, tempY, 130, tempY + 6);
    doc.line(165, tempY, 165, tempY + 6);
    tempY += 4;
    doc.setFont(undefined, "bold");
    doc.text(`Total Saldo Awal Tunai :`, 12, tempY);
    doc.text(`${tempSisa.toLocaleString("en-US")}`, 160, tempY, {
      align: "right",
    });
    doc.setFont(undefined, "normal");
    tempY += 2;
    doc.line(130, tempY, 165, tempY);

    let keysTotalBank = Object.keys(jurnalPostingsTotalBank.data);

    for (let j = 0; j < keysTotalBank.length; j++) {
      tempSaldoAwalAllBank.push(0);
    }

    for (let j = 0; j < keysTotalBank.length; j++) {
      tempY += 5;
      doc.setFont(undefined, "bold");
      doc.text(
        `Saldo Awal : ${
          jurnalPostingsTotalBank.data[keysTotalBank[j]][0].namaCOA
        }`,
        12,
        tempY
      );
      doc.setFont(undefined, "normal");
      tempY += 3;
      doc.line(10, tempY, 230, tempY);
      doc.line(20, tempY, 20, tempY + 6);
      doc.line(50, tempY, 50, tempY + 6);
      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      doc.line(200, tempY, 200, tempY + 6);
      tempY += 4;
      doc.text(`1`, 15, tempY);
      doc.text(`S.AWAL`, 22, tempY);
      doc.text(`Saldo Bank Per Tanggal ${sampaiTglMinOne}`, 53, tempY);
      let tempSisaSaldoBank =
        (jurnalPostingsTotalBank.data[keysTotalBank[j]][0].jumlah > 0
          ? jurnalPostingsTotalBank.data[keysTotalBank[j]][0].jumlah
          : 0) +
        (tempPenerimaanBankSampaiSekarangAll[j] > 0
          ? tempPenerimaanBankSampaiSekarangAll[j]
          : 0) -
        (tempPengeluaranBankSampaiSekarangAll[j] > 0
          ? tempPengeluaranBankSampaiSekarangAll[j]
          : 0);
      doc.text(
        `${tempSisaSaldoBank.toLocaleString("en-US")}`,

        160,
        tempY,
        {
          align: "right",
        }
      );
      tempSisa += tempSisaSaldoBank;
      tempSaldoAwalBank += tempSisaSaldoBank;
      tempSaldoAwalAllBank[j] += tempSisaSaldoBank;
      doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
        align: "right",
      });
      tempY += 2;
      doc.line(10, tempY, 230, tempY);
      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      tempY += 4;
      doc.setFontSize(8);
      doc.setFont(undefined, "bold");
      doc.text(
        `Total Saldo Awal : ${
          jurnalPostingsTotalBank.data[keysTotalBank[j]][0].namaCOA
        }`,
        12,
        tempY
      );
      doc.setFontSize(9);
      doc.text(`${tempSisaSaldoBank.toLocaleString("en-US")}`, 160, tempY, {
        align: "right",
      });
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(130, tempY, 165, tempY);
    }

    doc.line(130, tempY, 130, tempY + 6);
    doc.line(165, tempY, 165, tempY + 6);
    tempY += 4;
    doc.setFont(undefined, "bold");
    doc.text(`Total Saldo Awal Bank :`, 12, tempY);
    doc.text(`${tempSaldoAwalBank.toLocaleString("en-US")}`, 160, tempY, {
      align: "right",
    });
    doc.setFont(undefined, "normal");
    tempY += 2;
    doc.line(130, tempY, 165, tempY);

    doc.line(130, tempY, 130, tempY + 6);
    doc.line(165, tempY, 165, tempY + 6);
    tempY += 4;
    doc.setFont(undefined, "bold");
    doc.text(`Total Saldo Awal :`, 12, tempY);
    doc.text(`${tempSisa.toLocaleString("en-US")}`, 160, tempY, {
      align: "right",
    });
    doc.setFont(undefined, "normal");
    tempY += 2;
    doc.line(130, tempY, 165, tempY);

    if (
      Object.keys(jurnalPostingsPenerimaanKas.data).length > 0 ||
      Object.keys(jurnalPostingsPenerimaanBank.data).length > 0
    ) {
      tempY += 8;
      let noPenerimaanKas = 1;
      doc.setFont(undefined, "bold");
      doc.text(`Penerimaan :`, 12, tempY);
      doc.setFont(undefined, "normal");
      let keysPenerimaanKas = Object.keys(jurnalPostingsPenerimaanKas.data);

      for (let j = 0; j < keysPenerimaanKas.length; j++) {
        tempPenerimaanAllKas.push(0);
      }

      for (let j = 0; j < keysPenerimaanKas.length; j++) {
        if (tempY > 270) {
          doc.addPage();
          tempY = tempYStart;
          hal++;
          doc.text(`Hal: ${hal}`, 220, 5);
          doc.text(
            `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
            10,
            290
          );
        }
        tempY += 5;
        doc.setFont(undefined, "bold");
        doc.text(
          `Penerimaan : ${
            jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][0].namaCOA
          }`,
          14,
          tempY
        );
        doc.setFont(undefined, "normal");
        for (
          let i = 0;
          i < jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]].length;
          i++
        ) {
          if (tempY > 270) {
            doc.addPage();
            tempY = tempYStart;
            hal++;
            doc.text(`Hal: ${hal}`, 220, 5);
            doc.text(
              `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
              10,
              290
            );
          }
          tempY += 3;
          doc.line(10, tempY, 230, tempY);
          doc.line(20, tempY, 20, tempY + 6);
          doc.line(50, tempY, 50, tempY + 6);
          doc.line(130, tempY, 130, tempY + 6);
          doc.line(165, tempY, 165, tempY + 6);
          doc.line(200, tempY, 200, tempY + 6);
          tempY += 4;
          doc.text(`${noPenerimaanKas}`, 15, tempY);
          noPenerimaanKas++;
          doc.text(
            `${
              jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][i].noBukti
            }`,
            22,
            tempY
          );
          doc.text(
            `${jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][
              i
            ].keterangan.slice(0, 40)}`,
            53,
            tempY
          );
          doc.text(
            `${jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][
              i
            ].jumlah.toLocaleString("en-US")}`,
            160,
            tempY,
            {
              align: "right",
            }
          );
          tempSisa +=
            jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][i].jumlah;
          tempPenerimaanUangTunai +=
            jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][i].jumlah;
          tempPenerimaanAllKas[j] +=
            jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][i].jumlah;
          tempTotalPenerimaan +=
            jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][i].jumlah;
          doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
            align: "right",
          });
        }
        tempY += 2;
        doc.line(10, tempY, 230, tempY);
        doc.line(130, tempY, 130, tempY + 6);
        doc.line(165, tempY, 165, tempY + 6);
        tempY += 4;
        doc.setFont(undefined, "bold");
        doc.text(
          `Total Penerimaan : ${
            jurnalPostingsPenerimaanKas.data[keysPenerimaanKas[j]][0].namaCOA
          }`,
          14,
          tempY
        );
        doc.text(
          `${tempPenerimaanUangTunai.toLocaleString("en-US")}`,
          160,
          tempY,
          {
            align: "right",
          }
        );
        tempPenerimaanUangTunai = 0;
        doc.setFont(undefined, "normal");
        tempY += 2;
        doc.line(130, tempY, 165, tempY);
      }

      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      tempY += 4;
      doc.setFont(undefined, "bold");
      let tempTotalPenerimaanKas = tempTotalPenerimaan;
      doc.text(`Total Penerimaan Kas :`, 12, tempY);
      doc.text(`${tempTotalPenerimaan.toLocaleString("en-US")}`, 160, tempY, {
        align: "right",
      });
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(130, tempY, 165, tempY);

      let noPenerimaanBank = 1;
      let keysPenerimaanBank = Object.keys(jurnalPostingsPenerimaanBank.data);

      for (let j = 0; j < keysPenerimaanBank.length; j++) {
        tempPenerimaanAllBank.push(0);
      }

      for (let j = 0; j < keysPenerimaanBank.length; j++) {
        if (tempY > 270) {
          doc.addPage();
          tempY = tempYStart;
          hal++;
          doc.text(`Hal: ${hal}`, 220, 5);
          doc.text(
            `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
            10,
            290
          );
        }
        tempY += 5;
        doc.setFont(undefined, "bold");
        doc.text(
          `Penerimaan : ${
            jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][0].namaCOA
          }`,
          14,
          tempY
        );
        doc.setFont(undefined, "normal");
        for (
          let i = 0;
          i < jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]].length;
          i++
        ) {
          if (tempY > 270) {
            doc.addPage();
            tempY = tempYStart;
            hal++;
            doc.text(`Hal: ${hal}`, 220, 5);
            doc.text(
              `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
              10,
              290
            );
          }
          tempY += 3;
          doc.line(10, tempY, 230, tempY);
          doc.line(20, tempY, 20, tempY + 6);
          doc.line(50, tempY, 50, tempY + 6);
          doc.line(130, tempY, 130, tempY + 6);
          doc.line(165, tempY, 165, tempY + 6);
          doc.line(200, tempY, 200, tempY + 6);
          tempY += 4;
          doc.text(`${noPenerimaanBank}`, 15, tempY);
          noPenerimaanBank++;
          doc.text(
            `${
              jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][i]
                .noBukti
            }`,
            22,
            tempY
          );
          doc.text(
            `${jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][
              i
            ].keterangan.slice(0, 40)}`,
            53,
            tempY
          );
          doc.text(
            `${jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][
              i
            ].jumlah.toLocaleString("en-US")}`,
            160,
            tempY,
            {
              align: "right",
            }
          );
          tempSisa +=
            jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][i].jumlah;
          tempPenerimaanBank +=
            jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][i].jumlah;
          tempPenerimaanAllBank[j] +=
            jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][i].jumlah;
          tempTotalPenerimaan +=
            jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][i].jumlah;
          doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
            align: "right",
          });
        }
        tempY += 2;
        doc.line(10, tempY, 230, tempY);
        doc.line(130, tempY, 130, tempY + 6);
        doc.line(165, tempY, 165, tempY + 6);
        tempY += 4;
        doc.setFont(undefined, "bold");
        doc.text(
          `Total Penerimaan : ${
            jurnalPostingsPenerimaanBank.data[keysPenerimaanBank[j]][0].namaCOA
          }`,
          14,
          tempY
        );
        doc.text(`${tempPenerimaanBank.toLocaleString("en-US")}`, 160, tempY, {
          align: "right",
        });
        tempPenerimaanBank = tempPenerimaanBank;
        doc.setFont(undefined, "normal");
        tempY += 2;
        doc.line(130, tempY, 165, tempY);
      }

      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      tempY += 4;
      doc.setFont(undefined, "bold");
      doc.text(`Total Penerimaan Bank :`, 12, tempY);
      doc.text(
        `${(tempTotalPenerimaan - tempTotalPenerimaanKas).toLocaleString(
          "en-US"
        )}`,
        160,
        tempY,
        {
          align: "right",
        }
      );
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(130, tempY, 165, tempY);

      doc.line(130, tempY, 130, tempY + 6);
      doc.line(165, tempY, 165, tempY + 6);
      tempY += 4;
      doc.setFont(undefined, "bold");
      doc.text(`Total Penerimaan :`, 12, tempY);
      doc.text(`${tempTotalPenerimaan.toLocaleString("en-US")}`, 160, tempY, {
        align: "right",
      });
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(130, tempY, 165, tempY);
    }

    if (
      Object.keys(jurnalPostingsPengeluaranKas.data).length > 0 ||
      Object.keys(jurnalPostingsPengeluaranBank.data).length > 0
    ) {
      tempY += 8;
      let noPengeluaranKas = 1;
      doc.setFont(undefined, "bold");
      doc.text(`Pengeluaran :`, 12, tempY);
      doc.setFont(undefined, "normal");
      let keysPengeluaranKas = Object.keys(jurnalPostingsPengeluaranKas.data);

      for (let j = 0; j < keysPengeluaranKas.length; j++) {
        tempPengeluaranAllKas.push(0);
      }

      for (let j = 0; j < keysPengeluaranKas.length; j++) {
        if (tempY > 270) {
          doc.addPage();
          tempY = tempYStart;
          hal++;
          doc.text(`Hal: ${hal}`, 220, 5);
          doc.text(
            `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
            10,
            290
          );
        }
        tempY += 5;
        doc.setFont(undefined, "bold");
        doc.text(
          `Pengeluaran : ${
            jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][0].namaCOA
          }`,
          14,
          tempY
        );
        doc.setFont(undefined, "normal");
        for (
          let i = 0;
          i < jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]].length;
          i++
        ) {
          if (tempY > 270) {
            doc.addPage();
            tempY = tempYStart;
            hal++;
            doc.text(`Hal: ${hal}`, 220, 5);
            doc.text(
              `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
              10,
              290
            );
          }
          tempY += 3;
          doc.line(10, tempY, 230, tempY);
          doc.line(20, tempY, 20, tempY + 6);
          doc.line(50, tempY, 50, tempY + 6);
          doc.line(130, tempY, 130, tempY + 6);
          doc.line(165, tempY, 165, tempY + 6);
          doc.line(200, tempY, 200, tempY + 6);
          tempY += 4;
          doc.text(`${noPengeluaranKas}`, 15, tempY);
          noPengeluaranKas++;
          doc.text(
            `${
              jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][i]
                .noBukti
            }`,
            22,
            tempY
          );
          doc.text(
            `${jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][
              i
            ].keterangan.slice(0, 40)}`,
            53,
            tempY
          );
          doc.text(
            `${jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][
              i
            ].jumlah.toLocaleString("en-US")}`,
            195,
            tempY,
            {
              align: "right",
            }
          );
          tempSisa -=
            jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][i].jumlah;
          tempPengeluaranUangTunai +=
            jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][i].jumlah;
          tempPengeluaranAllKas[j] +=
            jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][i].jumlah;
          tempTotalPengeluaran +=
            jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][i].jumlah;
          doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
            align: "right",
          });
        }
        tempY += 2;
        doc.line(10, tempY, 230, tempY);
        doc.line(165, tempY, 165, tempY + 6);
        doc.line(200, tempY, 200, tempY + 6);
        tempY += 4;
        doc.setFont(undefined, "bold");
        doc.text(
          `Total Pengeluaran : ${
            jurnalPostingsPengeluaranKas.data[keysPengeluaranKas[j]][0].namaCOA
          }`,
          14,
          tempY
        );
        doc.text(
          `${tempPengeluaranUangTunai.toLocaleString("en-US")}`,
          195,
          tempY,
          {
            align: "right",
          }
        );
        tempPengeluaranUangTunai = 0;
        doc.setFont(undefined, "normal");
        tempY += 2;
        doc.line(165, tempY, 200, tempY);
      }

      doc.line(165, tempY, 165, tempY + 6);
      doc.line(200, tempY, 200, tempY + 6);
      tempY += 4;
      doc.setFont(undefined, "bold");
      doc.text(`Total Pengeluaran Kas :`, 12, tempY);
      doc.text(`${tempTotalPengeluaran.toLocaleString("en-US")}`, 195, tempY, {
        align: "right",
      });
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(165, tempY, 200, tempY);

      let noPengeluaranBank = 1;
      let keysPengeluaranBank = Object.keys(jurnalPostingsPengeluaranBank.data);

      for (let j = 0; j < keysPengeluaranBank.length; j++) {
        tempPengeluaranAllBank.push(0);
      }

      for (let j = 0; j < keysPengeluaranBank.length; j++) {
        if (tempY > 270) {
          doc.addPage();
          tempY = tempYStart;
          hal++;
          doc.text(`Hal: ${hal}`, 220, 5);
          doc.text(
            `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
            10,
            290
          );
        }
        tempY += 5;
        doc.setFont(undefined, "bold");
        doc.text(
          `Pengeluaran : ${
            jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][0]
              .namaCOA
          }`,
          14,
          tempY
        );
        doc.setFont(undefined, "normal");
        for (
          let i = 0;
          i < jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]].length;
          i++
        ) {
          if (tempY > 270) {
            doc.addPage();
            tempY = tempYStart;
            hal++;
            doc.text(`Hal: ${hal}`, 220, 5);
            doc.text(
              `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
              10,
              290
            );
          }
          tempY += 3;
          doc.line(10, tempY, 230, tempY);
          doc.line(20, tempY, 20, tempY + 6);
          doc.line(50, tempY, 50, tempY + 6);
          doc.line(130, tempY, 130, tempY + 6);
          doc.line(165, tempY, 165, tempY + 6);
          doc.line(200, tempY, 200, tempY + 6);
          tempY += 4;
          doc.text(`${noPengeluaranBank}`, 15, tempY);
          noPengeluaranBank++;
          doc.text(
            `${
              jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][i]
                .noBukti
            }`,
            22,
            tempY
          );
          doc.text(
            `${jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][
              i
            ].keterangan.slice(0, 40)}`,
            53,
            tempY
          );
          doc.text(
            `${jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][
              i
            ].jumlah.toLocaleString("en-US")}`,
            195,
            tempY,
            {
              align: "right",
            }
          );
          tempSisa -=
            jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][i]
              .jumlah;
          tempPengeluaranBank +=
            jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][i]
              .jumlah;
          tempPengeluaranAllBank[j] +=
            jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][
              i
            ].jumlah;
          tempTotalPengeluaran +=
            jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][i]
              .jumlah;
          doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
            align: "right",
          });
        }
        tempY += 2;
        doc.line(10, tempY, 230, tempY);
        doc.line(165, tempY, 165, tempY + 6);
        doc.line(200, tempY, 200, tempY + 6);
        tempY += 4;
        doc.setFont(undefined, "bold");
        doc.text(
          `Total Pengeluaran : ${
            jurnalPostingsPengeluaranBank.data[keysPengeluaranBank[j]][0]
              .namaCOA
          }`,
          14,
          tempY
        );
        doc.text(`${tempPengeluaranBank.toLocaleString("en-US")}`, 195, tempY, {
          align: "right",
        });
        doc.setFont(undefined, "normal");
        tempY += 2;
        doc.line(165, tempY, 200, tempY);
      }

      doc.line(165, tempY, 165, tempY + 6);
      doc.line(200, tempY, 200, tempY + 6);
      tempY += 4;
      doc.setFont(undefined, "bold");
      doc.text(`Total Pengeluaran Bank :`, 12, tempY);
      doc.text(`${tempPengeluaranBank.toLocaleString("en-US")}`, 195, tempY, {
        align: "right",
      });
      tempPengeluaranBank = 0;
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(165, tempY, 200, tempY);

      if (tempY > 255) {
        doc.addPage();
        tempY = tempYStart;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }
      doc.line(165, tempY, 165, tempY + 6);
      doc.line(200, tempY, 200, tempY + 6);
      tempY += 4;
      doc.setFont(undefined, "bold");
      doc.text(`Total Pengeluaran :`, 12, tempY);
      doc.text(`${tempTotalPengeluaran.toLocaleString("en-US")}`, 195, tempY, {
        align: "right",
      });
      doc.setFont(undefined, "normal");
      tempY += 2;
      doc.line(165, tempY, 200, tempY);
    }

    tempY += 3;
    doc.line(10, tempY, 230, tempY);
    tempY += 6;
    doc.setFont(undefined, "bold");

    for (let j = 0; j < keysTotalKas.length; j++) {
      if (tempY > 270) {
        doc.addPage();
        tempY = tempYStart;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }
      tempY += 5;
      doc.text(
        `SALDO AKHIR ${
          jurnalPostingsTotalKas.data[keysTotalKas[j]][0].namaCOA
        } TANGGAL : ${formatDate(new Date(sampaiTgl))}`,
        12,
        tempY
      );
      doc.text(
        `${(
          (tempSaldoAwalAllKas[j] ? tempSaldoAwalAllKas[j] : 0) +
          (tempPenerimaanAllKas[j] ? tempPenerimaanAllKas[j] : 0) -
          (tempPengeluaranAllKas[j] ? tempPengeluaranAllKas[j] : 0)
        ).toLocaleString("en-US")}`,
        225,
        tempY,
        {
          align: "right",
        }
      );
    }

    for (let j = 0; j < keysTotalBank.length; j++) {
      if (tempY > 270) {
        doc.addPage();
        tempY = tempYStart;
        hal++;
        doc.text(`Hal: ${hal}`, 220, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          290
        );
      }
      tempY += 5;
      doc.text(
        `SALDO AKHIR ${
          jurnalPostingsTotalBank.data[keysTotalBank[j]][0].namaCOA
        } TANGGAL : ${formatDate(new Date(sampaiTgl))}`,
        12,
        tempY
      );
      doc.text(
        `${(
          (tempSaldoAwalAllBank[j] ? tempSaldoAwalAllBank[j] : 0) +
          (tempPenerimaanAllBank[j] ? tempPenerimaanAllBank[j] : 0) -
          (tempPengeluaranAllBank[j] ? tempPengeluaranAllBank[j] : 0)
        ).toLocaleString("en-US")}`,
        225,
        tempY,
        {
          align: "right",
        }
      );
    }

    tempY += 1;
    doc.line(200, tempY, 230, tempY);
    tempY += 6;
    doc.text(
      `SALDO AKHIR TANGGAL : ${formatDate(new Date(sampaiTgl))}`,
      12,
      tempY
    );
    doc.text(`${tempSisa.toLocaleString("en-US")}`, 225, tempY, {
      align: "right",
    });
    doc.setFont(undefined, "normal");
    tempY += 3;
    doc.line(200, tempY, 230, tempY);
    tempY += 0.5;
    doc.line(200, tempY, 230, tempY);

    tempPersenLoading = 100;
    setPersenLoading(tempPersenLoading.toString());

    setLoading(false);

    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Kas Harian
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Col sm={6}>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="3" style={textRight}>
              Per Tanggal :
            </Form.Label>
            <Col sm="9">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={inputSampaiTgl}
                onChange={(e) => setInputSampaiTgl(e)}
                customInput={
                  <TextField
                    error={error && inputSampaiTgl === null && true}
                    helperText={
                      error &&
                      inputSampaiTgl === null &&
                      "Sampai Tanggal harus diisi!"
                    }
                    sx={{ width: "100%" }}
                    size="small"
                  />
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Box>
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => {
            if (inputSampaiTgl === null) {
              setError(true);
            } else downloadPdf();
          }}
        >
          CETAK
        </Button>
      </Box>
    </Box>
  );
};

export default KasHarian;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "60vw"
  // }
};
